import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { CheckLogin, HandleVisibilityChange, HideErrorModal, InitWS, ResizeWindow, ViewUnreadNotificationCount } from './Services/Actions';
import { Link, Redirect, Route, Router, Switch } from 'react-router-dom';
// import LogRocket from 'logrocket';
import { Detector } from 'react-detect-offline';
import history from './history';
import moment from 'moment';
import { ToastContainer } from 'react-toastify';

import i18n from './Assets/Translations';

import styled from 'styled-components';

import AbelModal from './Components/Modal';
import AppContainer from './Components/AppContainer/index.jsx';
import Header2 from './Components/Navigation/Header';
import LeftNavigation from './Components/Navigation/LeftNavigation';
import Loading from './Components/Loading';
import MultiText from './Components/Text/MultiText';
import PageText from './Components/Text/PageText';

import { GA_Init, GA_PageView } from './Components/GoogleAnalytics';

import Account from './Pages/Account';
import AddAdditionalActivity from './Pages/AddAdditionalActivity';
import AddContract from './Pages/AddContract';
import AddDish from './Pages/AddDish';
import AddLogFood from './Pages/AddLogFood';
import AddMealTemplate from './Pages/AddMealTemplate';
import AddSingleGeneratedWorkout from './Pages/AddSingleGeneratedWorkout';
import AddTrainingProgram from './Pages/AddTrainingProgram';
import AddWorkout from './Pages/AddWorkout';
import AddWorkoutPlan from './Pages/AddWorkoutPlan';
import AddWorkoutTemplate from './Pages/AddWorkoutTemplate';
import AppleCallback from './Pages/AppleCallback';
import BodyGoal from './Pages/ClientProgress/BodyGoal';
import BulkAddClients from './Pages/BulkAddClients';
import ClientDetail from './Pages/ClientDetail';
import Clients from './Pages/Clients';
// import ComingSoon from './Pages/ComingSoon';
import CurusCallback from './Pages/CurusCallback';
import Companies from './Pages/Companies';
import DataCenter from './Pages/DataCenter';
import DataCenter_SuperAdmin from './Pages/DataCenter_SuperAdmin';
import Dish from './Pages/Dish';
import DishDetail from './Pages/DishDetail';
import EnduranceDetail from './Pages/ClientProgress/EnduranceDetail';
import EnduranceProgress from './Pages/ClientProgress/Endurance';
import Exercise from './Pages/Exercise';
import ExerciseDetail from './Pages/ClientProgress/ExerciseDetail';
import FirstTimeUser from './Pages/FirstTimeUser';
import Food from './Pages/Food';
import Frozen from './Pages/Frozen';
import GarminProgress from './Pages/ClientProgress/Garmin';
import GroupDetail from './Pages/GroupDetail';
import GroupWorkoutDetail from './Pages/GroupWorkoutDetail';
import Library from './Pages/Library';
import Login from './Pages/Login';
import Logout from './Pages/Logout';
import Manual from './Pages/Manual';
import MealPlanBuilder from './Pages/MealPlanBuilder';
import MealTemplate from './Pages/MealTemplate';
import Notifications from './Pages/Notifications';
import NutritionProgress from './Pages/ClientProgress/Nutrition';
import OrgCoaches from './Pages/OrgCoaches';
// import OrgIncome from './Pages/OrgIncome';
import OrgIncome2 from './Pages/OrgIncome2';
import Payments from './Pages/Payments';
import Products from './Pages/Payments/Products';
import SessionRestore from './Pages/SessionRestore';
import StrengthProgress from './Pages/ClientProgress/Strength';
import SubscriptionCallback from './Pages/SubscriptionCallback';
import SurveyProgress from './Pages/ClientProgress/SurveyProgress';
import StatusProgress from './Pages/ClientProgress/Status';
import TaskProgress from './Pages/ClientProgress/Task';
import TrainingProgram from './Pages/TrainingProgram';
import TransactionHistory from './Pages/TransactionHistory';
import WorkoutDetail from './Pages/WorkoutDetail';
import WorkoutLibrary from './Pages/Library2/Workout';
import WorkoutTemplate from './Pages/WorkoutTemplate';
import UserAccounts from './Pages/UserAccounts';
import VideoCalls from './Pages/VideoCalls';

import AddWorkouts from './Components/AddWorkouts';
import BodyMusclesTest from './BodyMusclesTest';
import CalculateMax from './CalculateMax';
import DeliverySite from './Pages/DeliverySite';
import UploadContent from './UploadContent';
import Video from './Video/Video';

import { SetLanguage } from './Functions';

import BlueCircleBackground from './Assets/Illustrations/BlueCircleBackground.png';
import FrozenBackground from './Assets/Illustrations/FrozenBackground.png';

import "./Assets/Translations";
import 'react-image-crop/dist/ReactCrop.css';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';

const BodyContainer = styled.div`
    align-items: ${props => (props.HasAccess && props.IsLoggedIn) ? 'unset' : 'center'}
    // background: ${props => (props.HasAccess && props.IsLoggedIn) ? 'var(--chat-gray)' : `var(--blue-astronaut) url(${!props.HasAccess ? BlueCircleBackground : BlueCircleBackground}) no-repeat center center / cover;`};
    background: ${props => (props.HasAccess && props.IsLoggedIn) ? 'var(--chat-gray)' : (!!props.FirstTimeUser && !!props.IsLoggedIn) ? 'var(--white-concrete)' : (!props.HasAccess && props.IsLoggedIn) ? `url(${FrozenBackground}) no-repeat center center / cover` : `var(--blue-astronaut) url(${BlueCircleBackground}) no-repeat center center / cover`};
    display: flex;
    flex-direction: ${props => (props.HasAccess && props.IsLoggedIn) ? 'column' : 'row'};
    flex-grow: 1;
    justify-content: ${props => (props.HasAccess && props.IsLoggedIn) ? 'unset' : 'center'};
    
    min-height: 100%;
    ${props => !props.HasAccess && 'height: 100%;'}
    overflow: 'auto';
`;

const FixedHeaderContainer = styled.div`
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100000;
`;

const MainContent = styled.div`
    margin-left: ${props => props.IsHeaderMobile ? '0' : '93px'};
    width: ${props => props.IsHeaderMobile ? '100%' : 'calc(100% - 93px)'};
`;

const MembershipRequestContainer = styled.div`
    align-items: center;
    background-color: var(--blue-astronaut);
    display: flex;
    flex-direction: row;
    height: 51px;
    justify-content: center;
`;

const OfflineBar = styled.div`
    background-color: var(--red-bittersweet);
    color: var(--white);
    font-family: var(--font-family-semibold);
    font-size: var(--font-size-medium-2);
    padding: 7px 15px;
    text-align: center;
`;

const StyledAcceptLink = styled(Link)`
    color: var(--blue-abel);
    padding: 15px;
    
    @media(hover:hover) {
        :hover {
            color: var(--white);
            cursor: pointer;
        }
    }

    :active {
        color: var(--blue-abel);
    }
`;

const Wrapper = styled.div`
    display: flex;
    position: relative;
    margin-bottom: 24px;
    margin-top: 24px;

    // ${props => props.TopBar && `margin-top: ${props.TopBar}px;`}

    // ${props => (!!props.ShowFreeTrialBar) && 'margin-top: 51px;'}
    ${props => (!!props.ShowFreeTrialBar) && 'margin-top: 75px;'}
`;

const IGNORE_PATHS = [ '/session-restore', '/apple/callback', '/curus/callback', '/subscription/callback/AddSubscriber', '/subscription/callback/ReactivateUser', '/subscription/callback/RegisterUser', '/logout' ];

const TRANSLATION_UPDATE_CHECK_INTERVAL = 2 * 60 * 1000; // 2 minutes

class App extends React.Component {
    i18nIntervalId = null;

    state = {
        CurrentPathname: '',
        LanguageSet: 0,
        Loading: true,
        TopBarHeight: 0
    };

    componentDidMount() {
        // console.log('did mount');
        if (IGNORE_PATHS.indexOf(window.location.pathname) === -1) {
            // console.log('not in ignore paths');
            var i18nInterval = setInterval(() => {
                i18n.reloadResources();
                this.i18nIntervalId = null;
            }, TRANSLATION_UPDATE_CHECK_INTERVAL);

            this.i18nIntervalId = i18nInterval;

            this.props.CheckLogin().then(() => {
                // this.props.InitWS();

                // this.props.WSClient.onopen = () => console.log('WebSocket Client Connected');
                // this.props.WSClient.onmessage = message => console.log('message: ', message);

                this.props.ViewUnreadNotificationCount({ UserId: this.props.UserDetails.UserId });

                this.onSetupLanguage();

                if (!!+this.props.IsLoggedIn) {
                    // console.log('is logged in');
                    GA_Init({ UserId: this.props.UserDetails.UserId });
                    GA_PageView();
                    
                    // Set Window to Listen For Tab Being Visible / Hidden
                        this.props.HandleVisibilityChange();
                        document.addEventListener('visibilitychange', this.props.HandleVisibilityChange);
                    
                    this.setState({ Loading: false });
                } else {
                    // console.log('not logged in');
                    this.setState({ Loading: false });
                }

                // Set Router To Watch Changes
                    this.unlisten = history.listen(location => this.onUpdateCurrentLocation(location.pathname));

                // Set Window to Listen For Browser Resize
                    this.props.ResizeWindow();
                    window.addEventListener('resize', this.props.ResizeWindow);
            });
        } else this.setState({ Loading: false });
    }

    componentWillUnmount() {
        this.unlisten();
        clearInterval(this.i18nInterval);
        window.removeEventListener('resize', this.props.ResizeWindow);
        document.removeEventListener('visibilitychange', this.props.HandleVisibilityChange);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.state.LanguageSet) this.onSetupLanguage();

        // console.log('component did update');

        if (!this.state.Loading) {
            // console.log('not loading');
            // Logged Out User --> Remove and LogRocket
                if (!!+prevProps.IsLoggedIn && !!!+this.props.IsLoggedIn) document.removeEventListener('visibilitychange', this.props.HandleVisibilityChange);

            // Logged In User --> Setup Language, LogRocket
                if (prevProps.IsLoggedIn !== null && !!!+prevProps.IsLoggedIn && !!+this.props.IsLoggedIn) {
                    // console.log('logged in user');
                    this.onSetupLanguage();
                    // this.onSetupLogRocket();
                    
                    GA_Init({ UserId: this.props.UserDetails.UserId });
                    GA_PageView();
                }

                // if (!prevState.TopBarHeight || prevState.TopBarHeight !== this.state.TopBarHeight) {
                //     const TopBarHeight = this.divElement.clientHeight;
                //     console.log('TopBarHeight: ', TopBarHeight);
                //     this.setState({ TopBarHeight });
                // }

            // Not Visible Before, And Now Is --> Refresh Notification Count
                if (!!!prevProps.WindowVisible && !!this.props.WindowVisible && !!+this.props.IsLoggedIn) {
                    this.props.ViewUnreadNotificationCount({ UserId: this.props.UserDetails.UserId });
                }
        }
    }

    onChangeConnectionStatus = ConnectionStatus => {
        // console.log(`%cConnectionStatus: ${ConnectionStatus} at ${moment().format('YYYY-MM-DD HH:mm:ss')}`, 'color: #1A97FF; font-size: 20px;');
        if (!!!+this.props.IsLoggedIn) return;

        if (!!ConnectionStatus) this.props.ViewUnreadNotificationCount({ UserId: this.props.UserDetails.UserId });
    }

    onConnectionError = event => {
        // console.log(`%conConnectionError: ${event} at ${moment().format('YYYY-MM-DD HH:mm:ss')}`, 'color: #1A97FF; font-size: 20px;');
        // console.log('Connection Error event: ', event);
    }

    onConnectionStateChanged = event => {
        // console.log(`%conConnectionStateChanged: ${event} at ${moment().format('YYYY-MM-DD HH:mm:ss')}`, 'color: #1A97FF; font-size: 20px;');
    }

    onHideModal = () => {
        this.props.HideErrorModal();

        if (!this.props.ModalErrorNoLink) {
            if (this.props.ModalErrorLink) history.push(`/${this.props.ModalErrorLink}`);
            else history.push('/clients');
        }
    }

    onSetupLanguage = () => {
        if (!!this.props.UserDetails && Object.keys(this.props.UserDetails).length > 0) {
            SetLanguage(+this.props.UserDetails.LanguageId);
            this.setState({ LanguageSet: 1 });
        }
    }

    onSetupLogRocket = () => {
        // var { UserDetails } = this.props;
        // Identify User in LogRocket
        // LogRocket.identify(`${UserDetails.UserId}`, {
        //     name: UserDetails.UserName,
        //     email: UserDetails.Email,
        // });
    }

    onUpdateCurrentLocation = CurrentPathname => {
        GA_PageView();
        
        this.setState({ CurrentPathname });
    }

    renderErrorModal = () => {
        if (this.props.ShowErrorModal) {
            return (
                <AbelModal
                    IsErrorModal
                    OnHide={this.onHideModal}
                    Show={this.props.ShowErrorModal}
                    Size="large"
                    TopButton="cancel" 
                    TopButtonOnClick={this.onHideModal}
                >
                    {this.props.ModalError}
                </AbelModal>
            )
        }
    }

    renderFreeTrialRemainingDays = () => {
        var { t } = this.props;
        var { IsHeaderMobile } = this.props;
        var { promotionExpireDate } = this.props.UserDetails.SubscriptionStatus;

        var TextName = IsHeaderMobile ? 'subscription_using_free_trial_mobile' : 'subscription_using_free_trial';

        return (
            <MembershipRequestContainer className="MembershipRequestContainer">
                <MultiText
                    Texts={[
                        { FontColor: 'white', FontFamily: 'medium', FontSize: 'medium-1', Text: t(TextName, { DaysLeft: moment(promotionExpireDate).diff(moment().format('YYYY-MM-DD'), 'days') }) }
                    ]}
                />

                <StyledAcceptLink to={'/account?section=subscriptions'}>
                    <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('subscription_upgrade')} TextAlign="center" />
                </StyledAcceptLink>
            </MembershipRequestContainer>
        );
    }

    renderMembershipRequests = () => {
        var { t } = this.props;
        var { MembershipRequests } = this.props.UserDetails;

        return (
            <MembershipRequestContainer className="MembershipRequestContainer">
                <MultiText
                    Texts={[
                        { FontColor: 'white', FontFamily: 'medium', FontSize: 'medium-1', Text: `${t('membership_request_from')}:` },
                        { FontColor: 'white', FontFamily: 'bold', FontSize: 'medium-1', Text: MembershipRequests }
                    ]}
                />

                <StyledAcceptLink to={'/account?section=subscriptions'}>
                    <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('accept')} TextAlign="center" />
                </StyledAcceptLink>
            </MembershipRequestContainer>
        );
    }

    renderOnline = () => {
        var { t } = this.props;
        var { IsHeaderMobile, IsLoggedIn, UserDetails } = this.props;

        if (this.state.Loading) return <Loading />

        var { OrganizationId, SubscriptionStatus } = UserDetails;

        var IsFirstTimeUser = (SubscriptionStatus && !SubscriptionStatus.frozenSince) ? true : false;
        var HasAccess = (SubscriptionStatus && !!SubscriptionStatus.hasAccess) ? true : false;

        var { TopBarHeight } = this.state;

        var ShowFreeTrialBar = !!(IsLoggedIn && HasAccess && !OrganizationId && SubscriptionStatus && SubscriptionStatus.promotionType === 'Trial' && SubscriptionStatus.subscriberTypeId !== 3 && +SubscriptionStatus.bonusDaysLeft > 0 && !SubscriptionStatus.hasPaymentCardAdded);

        var HasCompaniesAccess = UserDetails.DataCenterAccess;
        var HasDataCenterAccess = UserDetails.DataCenterAccess;
        var HasUserAccountsAccess = UserDetails.UserAccountsAccess;
        var HasBulkUploadAccess = +UserDetails.UserId === 25734 || +UserDetails.UserId === 4871;

		return (
            <>
                {/* {() && <Loading />} */}

                <ToastContainer />

                <BodyContainer FirstTimeUser={IsFirstTimeUser} HasAccess={HasAccess} IsLoggedIn={IsLoggedIn}>
                    <Router history={history}>
                        {/* {!!IsLoggedIn && <Header CurrentPathname={this.state.CurrentPathname} />} */}

                        <Detector
                            onChange={this.onChangeConnectionStatus}
                            render={({ online }) => !online && <OfflineBar>{t('offline_message')}</OfflineBar>}
                        />

                        <FixedHeaderContainer className="FixedHeaderContainer" ref={divElement => { this.divElement = divElement } }>
                            {HasAccess && !!UserDetails.MembershipRequests && !UserDetails.OrganizationId && this.renderMembershipRequests()}
                            {!!ShowFreeTrialBar && this.renderFreeTrialRemainingDays()}
                        </FixedHeaderContainer>

                        {
                            !IsLoggedIn ?
                            <Switch>
                                <Route path="/login" exact component={Login} />

                                <Route path="/session-restore" component={SessionRestore} />

                                <Route path="/apple/callback" component={AppleCallback} />
                                <Route path="/subscription/callback/:type?" component={SubscriptionCallback} />

                                <Route path="/logout" exact component={Logout} />
                            </Switch>
                        :
                            !HasAccess ?
                                !!SubscriptionStatus.frozenSince ?
                                    <Switch>
                                        <Route path="/" exact component={Frozen} />
                                        <Route path="/session-restore" component={SessionRestore} />
                                        <Route path="/subscription/callback/:type?" component={SubscriptionCallback} />

                                        <Route path="/logout" exact component={Logout} />

                                        <Redirect to="/" />
                                    </Switch>
                                :
                                    <Switch>
                                        <Route path="/" exact component={FirstTimeUser} />
                                        <Route path="/session-restore" component={SessionRestore} />
                                        <Route path="/subscription/callback/:type?" component={SubscriptionCallback} />

                                        <Route path="/logout" exact component={Logout} />

                                        <Redirect to="/" />
                                    </Switch>
                        :
                            <AppContainer>
                                <Wrapper className="Wrapper" ShowFreeTrialBar={ShowFreeTrialBar} TopBar={TopBarHeight}>
                                    {!!IsLoggedIn && !IsHeaderMobile && <LeftNavigation CurrentPathname={this.state.CurrentPathname} TopBar={TopBarHeight} />}
                                    <MainContent IsHeaderMobile={IsHeaderMobile}>
                                        {!!IsLoggedIn && <Header2 CurrentPathname={this.state.CurrentPathname} />}

                                        {
                                            !OrganizationId ?
                                            <Switch>
                                                <Route path="/login" exact component={Login} />

                                                <Route path="/session-restore" component={SessionRestore} />

                                                <Route path="/add-workouts" exact component={AddWorkouts} />
                                                <Route path="/body-muscles" exact component={BodyMusclesTest} />
                                                <Route path="/calculate-max" exact component={CalculateMax} />
                                                <Route path="/delivery-site" exact component={DeliverySite} />
                                                <Route path="/meal-plan-builder" exact component={MealPlanBuilder} />
                                                <Route path="/video" exact component={Video} />
                                                <Route path="/upload-content" exact component={UploadContent} />

                                                <Route path="/account" exact component={Account} />

                                                <Route path="/library-workouts" exact component={WorkoutLibrary} />

                                                <Route path="/library" exact component={Library} />
                                                <Route path="/library/dish/:DishId?" exact component={Dish} />
                                                <Route path="/library/exercise/:ExerciseId?" exact component={Exercise} />
                                                <Route path="/library/food/:FoodId?" exact component={Food} />
                                                <Route path="/library/meal-template/:TemplateId?" exact component={MealTemplate} />
                                                <Route path="/library/training-program/:TrainingProgramId?" exact component={TrainingProgram} />
                                                <Route path="/library/workout-template/:TemplateId?" exact component={WorkoutTemplate} />

                                                <Route path="/notifications" exact component={Notifications} />

                                                {/* <Route path="/apple/callback" component={AppleCallback} /> */}
                                                <Route path="/curus/callback" component={CurusCallback} />
                                                
                                                <Route path="/subscription/callback/:type?" component={SubscriptionCallback} />

                                                <Route path="/clients" exact component={Clients} />

                                                <Route path="/add-additional-activity" exact component={AddAdditionalActivity} />
                                                <Route path="/add-contract" exact component={AddContract} />
                                                <Route path="/add-dish" exact component={AddDish} />
                                                <Route path="/add-log-food" exact component={AddLogFood} />
                                                <Route path="/add-meal-template" exact component={AddMealTemplate} />
                                                <Route path="/add-training-program" exact component={AddTrainingProgram} />
                                                <Route path="/add-single-generated-workout" exact component={AddSingleGeneratedWorkout} />
                                                <Route path="/add-workout" exact component={AddWorkout} />
                                                <Route path="/add-workout-plan" exact component={AddWorkoutPlan} />
                                                <Route path="/add-workout-template" exact component={AddWorkoutTemplate} />

                                                <Route path="/client/:ClientId" exact component={ClientDetail} />
                                                <Route path="/client/:ClientId/endurance/:EnduranceTemplateId" exact component={EnduranceDetail} />
                                                <Route path="/client/:ClientId/exercise/:ExerciseId" exact component={ExerciseDetail} />
                                                <Route path="/client/:ClientId/endurance" exact component={EnduranceProgress} />
                                                <Route path="/client/:ClientId/nutrition" exact component={NutritionProgress} />
                                                <Route path="/client/:ClientId/strength" exact component={StrengthProgress} />
                                                <Route path="/client/:ClientId/surveyprogress/:SurveyType" exact component={SurveyProgress} />
                                                <Route path="/client/:ClientId/userstatus/:StatusId" exact component={StatusProgress} />
                                                <Route path="/client/:ClientId/usertask/:TaskId" exact component={TaskProgress} />
                                                <Route path="/client/:ClientId/garmin/:DataType" exact component={GarminProgress} />
                                                <Route path="/client/:ClientId/:TrackingId/:BodyAreaId?" exact component={BodyGoal} />

                                                <Route path="/group/workout/:GroupWorkoutId" exact component={GroupWorkoutDetail} />
                                                <Route path="/group/:GroupId" exact component={GroupDetail} />

                                                <Route path="/manual" exact component={Manual} />

                                                <Route path="/video-calls" exact component={VideoCalls} />

                                                <Route path="/meal/:MealPlanUId" exact component={DishDetail} />

                                                <Route path="/sales" exact component={Payments} />

                                                <Route path="/workout/:WorkoutId" exact component={WorkoutDetail} />

                                                {HasCompaniesAccess && <Route path="/companies" exact component={Companies} />}
                                                
                                                {HasDataCenterAccess && <Route path="/datacenter" exact component={DataCenter} />}

                                                {HasUserAccountsAccess && <Route path="/user-accounts" exact component={UserAccounts} />}

                                                {HasUserAccountsAccess && <Route path="/transaction-history" exact component={TransactionHistory} />}

                                                {HasBulkUploadAccess && <Route path="/bulk-upload" exact component={BulkAddClients} />}

                                                <Route path="/logout" exact component={Logout} />

                                                <Redirect to="/clients" />
                                            </Switch>
                                        :
                                            <Switch>
                                                <Route path="/login" exact component={Login} />

                                                <Route path="/session-restore" component={SessionRestore} />

                                                <Route path="/account" exact component={Account} />

                                                <Route path="/library-workouts" exact component={WorkoutLibrary} />

                                                <Route path="/library" exact component={Library} />
                                                <Route path="/library/dish/:DishId?" exact component={Dish} />
                                                <Route path="/library/exercise/:ExerciseId?" exact component={Exercise} />
                                                <Route path="/library/food/:FoodId?" exact component={Food} />
                                                <Route path="/library/meal-template/:TemplateId?" exact component={MealTemplate} />
                                                <Route path="/library/training-program/:TrainingProgramId?" exact component={TrainingProgram} />
                                                <Route path="/library/workout-template/:TemplateId?" exact component={WorkoutTemplate} />

                                                <Route path="/notifications" exact component={Notifications} />

                                                <Route path="/datacenter" exact component={DataCenter_SuperAdmin} />

                                                {/* <Route path="/apple/callback" component={AppleCallback} /> */}
                                                <Route path="/curus/callback" component={CurusCallback} />

                                                <Route path="/products" exact component={Products} />

                                                <Route path="/coaches" exact component={OrgCoaches} />

                                                <Route path="/income" exact component={OrgIncome2} />

                                                {HasBulkUploadAccess && <Route path="/bulk-upload" exact component={BulkAddClients} />}

                                                <Route path="/logout" exact component={Logout} />

                                                <Redirect to="/income" />
                                            </Switch>
                                        }
                                    </MainContent>
                                </Wrapper>
                            </AppContainer>
                        }
                    </Router>

                    {this.renderErrorModal()}
                </BodyContainer>
            </>
		);
    }

	render () {
        if (1 < 2) return this.renderOnline();

        // return (
        //     <>
        //         <Offline>You are offline</Offline>
        //         <Online>{this.renderOnline()}</Online>
        //     </>
        // );
	}
}

const mapStateToProps = state => {
    return {
        IsLoggedIn: state.Auth.IsLoggedIn,
        ModalError: state.Auth.ModalError,
        ModalErrorLink: state.Auth.ModalErrorLink,
        ModalErrorNoLink: state.Auth.ModalErrorNoLink,
        ShowErrorModal: state.Auth.ShowErrorModal,
        UserDetails: state.Auth.UserDetails,

        IsHeaderMobile: state.Window.IsHeaderMobile,
        WindowVisible: state.Window.WindowVisible,
        WSClient: state.Window.WSClient,

        TryingLoginUser: state.Auth.TryingLoginUser,
        TryingLoginUserError: state.Auth.TryingLoginUserError
    };
};

export default withTranslation()(connect(mapStateToProps, { CheckLogin, HandleVisibilityChange, HideErrorModal, InitWS, ResizeWindow, ViewUnreadNotificationCount } )(App));