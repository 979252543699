import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { GetGarminData_BodyBattery, GetGarminData_Sleep, GetGarminData_Stress } from '../../../Services/Actions';
import history from '../../../history';
import moment from 'moment';

import { BodyGoalChart, BodyGoalContainer, BodyGoalHeaderContainer, BodyGoalProgressContainer, BodyGoalTitleContainer, CurrentStatusContainer, CurrentStatusBox, LinkContainer, LinkInnerContainer, StyledHR } from '../styles';

import ButtonGroup from '../../../Components/Buttons/ButtonGroup';
import LineGraph from '../../../Components/Charts/LineGraph';
import PageText from '../../../Components/Text/PageText';
import Spacer from '../../../Components/Spacer';

import { SecondsToTime } from '../../../Functions';

import { ReactComponent as GarminSVG } from '../../../Assets/SVG/Garmin.svg';

const BASE_CHART_DATA = {
    Goals: [],
    Increment: 0,
    Maximum: 0,
    Measurements: [],
    Measurements_Max: [],
    Measurements_Min: [],
    Minimum: 0
}

const BASE_CHART_METADATA = {
    CurrentValue: 0,
    HasData: false,
    StartValue: 0,
    SubTitle: ''
}

const RoundFunc = NumberToRound => {
    return Math.round(NumberToRound * 100) / 100;
}

class GarminData extends React.Component {
    _isMounted = false;

    state = {
        EndDate: `${moment().format('YYYY-MM-DD')}T23:59:59.999Z`,
        StartDate: `${moment().subtract(4, 'weeks').format('YYYY-MM-DD')}T00:00:00.000Z`,

        BodyBattery: {
            Chart: { ...BASE_CHART_DATA },
            ...BASE_CHART_METADATA
        },
        Sleep: {
            Chart: { ...BASE_CHART_DATA },
            ...BASE_CHART_METADATA
        },
        Stress: {
            Chart: { ...BASE_CHART_DATA },
            ...BASE_CHART_METADATA
        }
    };

    componentDidMount() {
        this._isMounted = true;

        this.onLoadGarminData();
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    onGoToBodyGoal = ({ BodyGoalLink }) => {
        history.push(BodyGoalLink);
    }

    onLoadGarminData = () => {
        this.onLoadBodyBattery();
        this.onLoadSleep();
        this.onLoadStress();
    }

    onLoadBodyBattery = () => {
        var { UserId } = this.props;
        var { EndDate, StartDate } = this.state;

        this.props.GetGarminData_BodyBattery({ EndDate, StartDate, UserId }).then(({ result: Measurements, increment: Increment, minimum: Minimum, maximum: Maximum }) => {
            var BodyBattery = { ...this.state.BodyBattery };
            BodyBattery.Chart = { ...BodyBattery.Chart, Increment, Maximum, Minimum };

            if (Measurements.length > 0) {
                BodyBattery.HasData = true;

                BodyBattery.Chart.Measurements_Max = [];
                BodyBattery.Chart.Measurements_Min = [];

                for (var i = 0; i < Measurements.length; i++) {
                    var { measurementMaximum, measurementMinimum, measurementDate: MeasurementDate } = Measurements[i];

                    BodyBattery.Chart.Measurements_Max.push({ Measurement: measurementMaximum, MeasurementDate, Units: '' });
                    BodyBattery.Chart.Measurements_Min.push({ Measurement: measurementMinimum, MeasurementDate, Units: '' });
                }

                BodyBattery.CurrentValue = Measurements[Measurements.length - 1].measurementMaximum; // Show Daily Max
                BodyBattery.StartValue = Measurements[Measurements.length - 1].measurementMinimum; // Show Daily Min
                BodyBattery.SubTitle = moment(Measurements[Measurements.length - 1].measurementDate).format('[NumbersDate]'); // Show Most Recently Synced Date
            }

            this.setState({ BodyBattery });
        });
    }

    onLoadSleep = () => {
        var { UserId } = this.props;
        var { EndDate, StartDate } = this.state;

        this.props.GetGarminData_Sleep({ EndDate, StartDate, UserId }).then(({ result: Measurements, increment: Increment, minimum: Minimum, maximum: Maximum }) => {
            var Sleep = { ...this.state.Sleep };
            Sleep.Chart = { ...Sleep.Chart, Increment, Maximum, Minimum };

            if (Measurements.length > 0) {
                Sleep.HasData = true;

                Sleep.Chart.Measurements = Measurements.map(({ measurement: Measurement, measurementDate: MeasurementDate }) => ({ IsTime: true, Measurement, MeasurementDate, Units: 'hours' }));

                Sleep.CurrentValue = SecondsToTime(Sleep.Chart.Measurements[Sleep.Chart.Measurements.length - 1].Measurement); // Show Most Recently Synced Amount
                Sleep.SubTitle = moment(Sleep.Chart.Measurements[Sleep.Chart.Measurements.length - 1].MeasurementDate).format('[NumbersDate]'); // Show Most Recently Synced Date
            }

            this.setState({ Sleep });
        });
    }

    onLoadStress = () => {
        var { UserId } = this.props;
        var { EndDate, StartDate } = this.state;

        this.props.GetGarminData_Stress({ EndDate, StartDate, UserId }).then(({ result: Measurements, increment: Increment, minimum: Minimum, maximum: Maximum }) => {
            var Stress = { ...this.state.Stress };
            Stress.Chart = { ...Stress.Chart, Increment, Maximum, Minimum };

            if (Measurements.length > 0) {
                Stress.HasData = true;

                Stress.Chart.Measurements = Measurements.map(({ measurement: Measurement, measurementDate: MeasurementDate }) => ({ Measurement: RoundFunc(Measurement), MeasurementDate, Units: '' }));

                Stress.CurrentValue = Stress.Chart.Measurements[Stress.Chart.Measurements.length - 1].Measurement; // Show Most Recently Synced Amount
                Stress.SubTitle = moment(Stress.Chart.Measurements[Stress.Chart.Measurements.length - 1].MeasurementDate).format('[NumbersDate]'); // Show Most Recently Synced Date
            }

            this.setState({ Stress });
        });
    }

    renderBodyGoalCurrentStatus = ({ CurrentValue, CurrentValueText, IsOnlyCurrent = false, StartValue, StartValueText }) => {
        var { t } = this.props;

        return (
            <CurrentStatusContainer IsOnlyCurrent={IsOnlyCurrent}>
                {
                    !IsOnlyCurrent &&
                    <CurrentStatusBox>
                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={StartValueText || t('StartValueText')} TextAlign="center" WordBreak="keep-all" />
    
                        <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="center" NoMargin Text={`${StartValue}`} TextAlign="center" WordBreak="keep-all" />
                    </CurrentStatusBox>
                }
                <CurrentStatusBox>
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={CurrentValueText || t('EndValueText')} TextAlign="center" WordBreak="keep-all" />

                    <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="center" NoMargin Text={`${CurrentValue}`} TextAlign="center" WordBreak="keep-all" />
                </CurrentStatusBox>
            </CurrentStatusContainer>
        );
    }

    renderBodyBattery = () => {
        var { t } = this.props;
        var { Chart, CurrentValue, StartValue, SubTitle } = this.state.BodyBattery;

        var BodyGoalLink = `/client/${this.props.UserId}/garmin/bodybattery`;

        var StartValueText = t('daily_low');
        var CurrentValueText = t('daily_high');

        return (
            <BodyGoalContainer>
                <BodyGoalHeaderContainer>
                    <BodyGoalTitleContainer>
                        <LinkContainer to={BodyGoalLink}>
                            <LinkInnerContainer className="LinkInnerContainer">
                                <GarminSVG />

                                <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('body_battery')} TextAlign="center" />
                            </LinkInnerContainer>
                        </LinkContainer>

                        <Spacer Size="extra-extra-small" />

                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('last_synced')}: ${SubTitle}`} TextAlign="left" />
                    </BodyGoalTitleContainer>
                    <BodyGoalProgressContainer>
                        {this.renderBodyGoalCurrentStatus({ CurrentValue, CurrentValueText, StartValue, StartValueText })}
                    </BodyGoalProgressContainer>
                </BodyGoalHeaderContainer>

                <StyledHR />

                <BodyGoalChart>
                    <LineGraph
                        DisplayMinMaxMeasurements
                        Goals={Chart.Goals}
                        Increment={Chart.Increment}
                        Maximum={Chart.Maximum}
                        Minimum={Chart.Minimum}
                        Measurements={Chart.Measurements}
                        Measurements_Max={Chart.Measurements_Max}
                        Measurements_Min={Chart.Measurements_Min}
                        SpanGaps={true}
                        TimePeriod="1M"
                        ToolTipConvertXDate
                        Units=""
                    />
                </BodyGoalChart>

                <Spacer Size="small" />

                <ButtonGroup Buttons={[{ FontFamily: 'semibold', OnClick: () => this.onGoToBodyGoal({ BodyGoalLink }), Title: t('track_progress') }]} />
            </BodyGoalContainer>
        );
    }

    renderSleep = () => {
        var { t } = this.props;
        var { Chart, CurrentValue, SubTitle } = this.state.Sleep;

        var BodyGoalLink = `/client/${this.props.UserId}/garmin/sleep`;

        var CurrentValueText = t('duration');

        return (
            <BodyGoalContainer>
                <BodyGoalHeaderContainer>
                    <BodyGoalTitleContainer>
                        <LinkContainer to={BodyGoalLink}>
                            <LinkInnerContainer className="LinkInnerContainer">
                                <GarminSVG />

                                <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('sleep')} TextAlign="center" />
                            </LinkInnerContainer>
                        </LinkContainer>

                        <Spacer Size="extra-extra-small" />

                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('last_synced')}: ${SubTitle}`} TextAlign="left" />
                    </BodyGoalTitleContainer>
                    <BodyGoalProgressContainer>
                        {this.renderBodyGoalCurrentStatus({ CurrentValue, CurrentValueText, IsOnlyCurrent: true })}
                    </BodyGoalProgressContainer>
                </BodyGoalHeaderContainer>

                <StyledHR />

                <BodyGoalChart>
                    <LineGraph
                        Goals={Chart.Goals}
                        Increment={Chart.Increment}
                        IsTime
                        Maximum={Chart.Maximum}
                        Minimum={Chart.Minimum}
                        Measurements={Chart.Measurements}
                        SpanGaps={true}
                        TimePeriod="1M"
                        ToolTipConvertXDate
                        ToolTipConvertYToSeconds
                        Units=""
                    />
                </BodyGoalChart>

                <Spacer Size="small" />

                <ButtonGroup Buttons={[{ FontFamily: 'semibold', OnClick: () => this.onGoToBodyGoal({ BodyGoalLink }), Title: t('track_progress') }]} />
            </BodyGoalContainer>
        );
    }

    renderStress = () => {
        var { t } = this.props;
        var { Chart, CurrentValue, SubTitle } = this.state.Stress;

        var BodyGoalLink = `/client/${this.props.UserId}/garmin/stress`;

        var CurrentValueText = t('average');

        return (
            <BodyGoalContainer>
                <BodyGoalHeaderContainer>
                    <BodyGoalTitleContainer>
                        <LinkContainer to={BodyGoalLink}>
                            <LinkInnerContainer className="LinkInnerContainer">
                                <GarminSVG />

                                <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('stress')} TextAlign="center" />
                            </LinkInnerContainer>
                        </LinkContainer>

                        <Spacer Size="extra-extra-small" />

                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('last_synced')}: ${SubTitle}`} TextAlign="left" />
                    </BodyGoalTitleContainer>
                    <BodyGoalProgressContainer>
                        {this.renderBodyGoalCurrentStatus({ CurrentValue, CurrentValueText, IsOnlyCurrent: true })}
                    </BodyGoalProgressContainer>
                </BodyGoalHeaderContainer>

                <StyledHR />

                <BodyGoalChart>
                    <LineGraph
                        Goals={Chart.Goals}
                        Increment={Chart.Increment}
                        Maximum={Chart.Maximum}
                        Minimum={Chart.Minimum}
                        Measurements={Chart.Measurements}
                        SpanGaps={true}
                        TimePeriod="1M"
                        ToolTipConvertXDate
                        Units=""
                    />
                </BodyGoalChart>

                <Spacer Size="small" />

                <ButtonGroup Buttons={[{ FontFamily: 'semibold', OnClick: () => this.onGoToBodyGoal({ BodyGoalLink }), Title: t('track_progress') }]} />
            </BodyGoalContainer>
        );
    }

    render() {
        var { BodyBattery, Sleep, Stress } = this.state;

        return (
            <>
                {BodyBattery.HasData && this.renderBodyBattery()}

                {Sleep.HasData && this.renderSleep()}

                {Stress.HasData && this.renderStress()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        TryingGetGarminData: state.Progress.TryingGetGarminData,
        TryingGetGarminDataError: state.Progress.TryingGetGarminDataError
    };
};

export default withTranslation()(connect(mapStateToProps, { GetGarminData_BodyBattery, GetGarminData_Sleep, GetGarminData_Stress } )(GarminData));