import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AddBodyMeasurements_BodyFat, AddBodyMeasurements_BodyWeight, AddBodyMeasurements_Circumference, AddBodyMeasurements_MuscleMass, ClearBodyGoal, ClearClientDetails, DeleteBodyMeasurement_BodyFat, DeleteBodyMeasurement_BodyWeight, DeleteBodyMeasurement_Circumference, DeleteBodyMeasurement_MuscleMass, GetBodyFatChart, GetBodyWeightChart, GetCircumferenceChart, GetMuscleMassChart, GetBodyFatTrackingHistory, GetBodyWeightTrackingHistory, GetCircumferenceTrackingHistory, GetMuscleMassTrackingHistory, ViewBodyFatProgress, ViewBodyWeightProgress, ViewCircumferenceProgress, ViewMuscleMassProgress } from '../../Services/Actions';
import history from '../../history';

import DateTime from 'react-datetime';
import moment from 'moment';

import Languages from '../../Config/languages';

import  { ActionIcon, BodyGoalChartContainer, BodyGoalChartHeaderContainer, BodyGoalChartHeaderDateContainer, BodyGoalChartHeaderTitleContainer, BodyGoalLineGraphContainer, CalendarIcon, ChartDateCustomContainer, ChartDateCustomTextContainer, ChartDatesPreMadeContainer, CurrentProgressContainer, CurrentTextContainer, EditGoalContainer, NoMeasurementsContainer, StyledGoal, StyledHR, StyledLineBar, StyledLoadMore, TimePeriodContainer, TimePeriodCheckmark, TrackingHistoryActionsContainer, TrackingHistoryContainer, TrackingHistoryRowBodyGoal, ViewCount } from './styles';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import LineGraph from '../../Components/Charts/LineGraph';
import Loading from '../../Components/Loading';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import AddMeasurementsModal from '../../Modals/AddMeasurements';
import ChooseDates from '../../Modals/ChooseDates';
import Info from '../../Modals/Info';

import Calendar from '../../Assets/Icons/Calendar.png';
import Delete_X from '../../Assets/Icons/Delete_X.png';
import Delete_X_Gray from '../../Assets/Icons/Delete_X_Gray.png';
import Edit_Dark from '../../Assets/Icons/Edit_Dark.png';
import Edit_Light from '../../Assets/Icons/Edit_Light.png';

class BodyGoalComponent extends React.PureComponent {
    _isMounted = false;

    state = {
        ActiveSearch: false,
        BodyAreaId: null,
        ChartEndDate: null,
        ChartStartDate: null,
        DateFormat: 'MM-DD-YYYY',
        FromEditing: {},
        Loading: true,
        PageNo: 1,
        PageSize: 15,
        ShowAddMeasurementsModal: false,
        ShowChooseDatesModal: false,
        ShowDeleteTrackingHistoryRecordModal: false,
        ShowEditTrackingHistoryRecordModal: false,
        TimeFormat: 'hh:mm a',
        TimePeriod: '1W',
        TrackingHistoryDetails: {},
        TrackingId: null
    };

    componentDidMount() {
        this._isMounted = true;

        var { BodyAreaId, ClientId, TrackingId } = this.props.match.params;
        var { LanguageId } = this.props.UserDetails;

        TrackingId = TrackingId.toLowerCase();

        if (!+ClientId || !TrackingId) history.push('/');
        else {
            if (TrackingId === 'bodyfat') BodyAreaId = 5;
            else if (TrackingId === 'caliper3') { BodyAreaId = 3; TrackingId = 'bodyfat'; }
            else if (TrackingId === 'caliper7') { BodyAreaId = 4; TrackingId = 'bodyfat'; }

            this.setState({
                BodyAreaId, TrackingId,
                DateFormat: Languages[`${LanguageId}`] ? Languages[`${LanguageId}`].longDateFormat["[NumbersDate]"] : Languages[`1`].longDateFormat["[NumbersDate]"],
                TimeFormat: Languages[`${LanguageId}`] ? Languages[`${LanguageId}`].longDateFormat["[TrackingHistoryTime]"] : Languages[`1`].longDateFormat["[TrackingHistoryTime]"]
            }, () => {
                if (TrackingId === 'bodyfat') this.onViewBodyFat();
                else if (TrackingId === 'bodyweight') this.onViewBodyWeight();
                else if (TrackingId === 'circumference') { if (!BodyAreaId) history.push('/'); this.onViewCircumference(); }
                else if (TrackingId === 'musclemass') this.onViewMuscleMass();
                else this.setState({ Loading: false });
            });
        }
    }
    
    componentWillUnmount() {
        this._isMounted = false;
        
        this.props.ClearClientDetails();
        this.props.ClearBodyGoal();
    }

    onAddMeasurement = () => {
        this.onToggleShowAddMeasurementsModal({ ShowAddMeasurementsModal: false });
    }

    onBuildChartDates = () => {
        var { ChartEndDate, ChartStartDate, TimePeriod } = this.state;

        if (!ChartEndDate && !ChartStartDate) {
            if (!TimePeriod) TimePeriod = '1W';

            if (TimePeriod === '1W') { ChartEndDate = moment().format('YYYY-MM-DD'); ChartStartDate = moment().subtract(1, 'weeks').format('YYYY-MM-DD') }
            else if (TimePeriod === '1M') { ChartEndDate = moment().format('YYYY-MM-DD'); ChartStartDate = moment().subtract(4, 'weeks').format('YYYY-MM-DD') }
            else if (TimePeriod === '1Y') { ChartEndDate = moment().format('YYYY-MM-DD'); ChartStartDate = moment().subtract(1, 'years').format('YYYY-MM-DD') }
            else { ChartEndDate = moment().format('YYYY-MM-DD'); ChartStartDate = moment(ChartStartDate).format('YYYY-MM-DD') }
        }

        return { ChartEndDate, ChartStartDate };
    }

    onBlurMeasurement = () => {
        var { Measurement } = this.state.TrackingHistoryDetails;

        if (+Measurement === 0) this.setState({ TrackingHistoryDetails: { ...this.state.TrackingHistoryDetails, Measurement: 0 } });
    }

    onChangeMeasurement = event => {
        this.setState({ TrackingHistoryDetails: { ...this.state.TrackingHistoryDetails, Measurement: event.target.value } });
    }

    onChangeMeasurementDate = MeasurementDate => {
        this.setState({ TrackingHistoryDetails: { ...this.state.TrackingHistoryDetails, MeasurementDate } });
    }

    onDeleteTrackingHistoryRecord = () => {
        var { ClientId } = this.props.match.params;
        var { BodyAreaId, PageNo, PageSize, TrackingHistoryDetails: { TrackingHistoryId }, TrackingId } = this.state;

        var { ChartEndDate, ChartStartDate } = this.onBuildChartDates();

        var TrackingPageNo = 1;
        var TrackingPageSize = PageNo * PageSize;

        if (TrackingId === 'bodyfat') this.props.DeleteBodyMeasurement_BodyFat({ BodyGoalTypeId: BodyAreaId, ChartEndDate, ChartStartDate, ClientId, TrackingHistoryId, TrackingPageNo, TrackingPageSize });
        else if (TrackingId === 'bodyweight') this.props.DeleteBodyMeasurement_BodyWeight({ ChartEndDate, ChartStartDate, ClientId, TrackingHistoryId, TrackingPageNo, TrackingPageSize });
        else if (TrackingId === 'circumference') this.props.DeleteBodyMeasurement_Circumference({ BodyAreaId, ChartEndDate, ChartStartDate, ClientId, TrackingHistoryId, TrackingPageNo, TrackingPageSize });
        else if (TrackingId === 'musclemass') this.props.DeleteBodyMeasurement_MuscleMass({ ChartEndDate, ChartStartDate, ClientId, TrackingHistoryId, TrackingPageNo, TrackingPageSize });

        this.onToggleShowDeleteTrackingHistoryRecordModal({ ShowDeleteTrackingHistoryRecordModal: false });
    }

    onEditTrackingHistoryRecord = () => {
        var { ClientId } = this.props.match.params;
        var { BodyAreaId, PageNo, PageSize, TrackingHistoryDetails: { Measurement, MeasurementDate, TrackingHistoryId, TrackingHistoryIndex }, TrackingId } = this.state;
        var { Measurement: PropsMeasurement, UpdateDate: PropsMeasurementDate } = this.props.BodyGoal.TrackingHistory.TrackingHistory[TrackingHistoryIndex];

        var TrackingPageNo = 1;
        var TrackingPageSize = PageNo * PageSize;

        MeasurementDate = moment(MeasurementDate).format();
        PropsMeasurementDate = moment(PropsMeasurementDate).format();

        if (+Measurement !== +PropsMeasurement || MeasurementDate !== PropsMeasurementDate) {
            var { ChartEndDate, ChartStartDate } = this.onBuildChartDates();

            if (TrackingId === 'bodyfat') this.props.AddBodyMeasurements_BodyFat({ BodyGoalTypeId: BodyAreaId, ChartEndDate, ChartStartDate, ClientId, GetDashboardData: 0, Measurement, MeasurementDate, TrackingHistoryId, TrackingPageNo, TrackingPageSize });
            else if (TrackingId === 'bodyweight') this.props.AddBodyMeasurements_BodyWeight({ ChartEndDate, ChartStartDate, ClientId, GetDashboardData: 0, Measurement, MeasurementDate, TrackingHistoryId, TrackingPageNo, TrackingPageSize });
            else if (TrackingId === 'circumference') {
                var Circumferences = [{ BodyAreaId, Current: Measurement, MeasurementDate, GoalMax: 0, GoalMin: 0 }];
                this.props.AddBodyMeasurements_Circumference({ BodyAreaId, ChartEndDate, ChartStartDate, Circumferences, ClientId, GetDashboardData: 0, Measurement, TrackingHistoryId, TrackingPageNo, TrackingPageSize });
            }
            else if (TrackingId === 'musclemass') this.props.AddBodyMeasurements_MuscleMass({ ChartEndDate, ChartStartDate, ClientId, GetDashboardData: 0, Measurement, MeasurementDate, TrackingHistoryId, TrackingPageNo, TrackingPageSize });
        }

        this.onToggleShowEditTrackingHistoryRecordModal({ ShowEditTrackingHistoryRecordModal: false });
    }

    onLoadTrackingHistory = Increment => {
        var { BodyAreaId, PageNo, PageSize, TrackingId } = this.state;
        var { ClientId } = this.props.match.params;

        if (Increment) PageNo = PageNo + 1;

        if (TrackingId === 'bodyfat') this.setState({ ActiveSearch: true, PageNo }, () => this.props.GetBodyFatTrackingHistory({ AddToExisting: Increment, BodyGoalTypeId: BodyAreaId, ClientId, TrackingPageNo: PageNo, TrackingPageSize: PageSize }).then(() => this.setState({ ActiveSearch: false })));
        else if (TrackingId === 'bodyweight') this.setState({ ActiveSearch: true, PageNo }, () => this.props.GetBodyWeightTrackingHistory({ AddToExisting: Increment, ClientId, TrackingPageNo: PageNo, TrackingPageSize: PageSize }).then(() => this.setState({ ActiveSearch: false })));
        else if (TrackingId === 'circumference') this.setState({ ActiveSearch: true, PageNo }, () => this.props.GetCircumferenceTrackingHistory({ AddToExisting: Increment, BodyAreaId, ClientId, TrackingPageNo: PageNo, TrackingPageSize: PageSize }).then(() => this.setState({ ActiveSearch: false })));
        else if (TrackingId === 'musclemass') this.setState({ ActiveSearch: true, PageNo }, () => this.props.GetMuscleMassTrackingHistory({ AddToExisting: Increment, ClientId, TrackingPageNo: PageNo, TrackingPageSize: PageSize }).then(() => this.setState({ ActiveSearch: false })));
    }

    onSelectCustomDates = ({ EndDate, StartDate }) => {
        this.onToggleShowChooseDatesModal(false);

        this.onUpdateChartDates({ ChartEndDate: EndDate, ChartStartDate: StartDate });
    }

    onSelectTimePeriod = ({ TimePeriod }) => {
        if (TimePeriod !== this.state.TimePeriod) {
            this.onUpdateChartDates({ TimePeriod })
        }
    }

    onToggleShowAddMeasurementsModal = ({ FromEditing = {}, ShowAddMeasurementsModal }) => {
        this.setState({ FromEditing, ShowAddMeasurementsModal });
    }

    onToggleShowChooseDatesModal = ShowChooseDatesModal => {
        this.setState({ ShowChooseDatesModal });
    }

    onToggleShowDeleteTrackingHistoryRecordModal = ({ ShowDeleteTrackingHistoryRecordModal, TrackingHistoryDetails = {} }) => {
        this.setState({ ShowDeleteTrackingHistoryRecordModal, TrackingHistoryDetails });
    }

    onToggleShowEditTrackingHistoryRecordModal = ({ ShowEditTrackingHistoryRecordModal, TrackingHistoryDetails = {} }) => {
        this.setState({ ShowEditTrackingHistoryRecordModal, TrackingHistoryDetails });
    }

    onUpdateChartDates = ({ ChartEndDate = null, ChartStartDate = null, TimePeriod = null }) => {
        if (TimePeriod === '1W') { ChartEndDate = moment().format('YYYY-MM-DD'); ChartStartDate = moment().subtract(1, 'weeks').format('YYYY-MM-DD') }
        else if (TimePeriod === '1M') { ChartEndDate = moment().format('YYYY-MM-DD'); ChartStartDate = moment().subtract(4, 'weeks').format('YYYY-MM-DD') }
        else if (TimePeriod === '1Y') { ChartEndDate = moment().format('YYYY-MM-DD'); ChartStartDate = moment().subtract(1, 'years').format('YYYY-MM-DD') }
        else { ChartEndDate = moment(ChartEndDate).format('YYYY-MM-DD'); ChartStartDate = moment(ChartStartDate).format('YYYY-MM-DD') }

        var { ClientId } = this.props.match.params;

        var { BodyAreaId, TrackingId } = this.state;
        if (TrackingId === 'bodyfat') this.props.GetBodyFatChart({ BodyGoalTypeId: BodyAreaId, ChartEndDate, ChartStartDate, ClientId }).then(() => this.setState({ ChartEndDate, ChartStartDate, TimePeriod }));
        else if (TrackingId === 'bodyweight') this.props.GetBodyWeightChart({ ChartEndDate, ChartStartDate, ClientId }).then(() => this.setState({ ChartEndDate, ChartStartDate, TimePeriod }));
        else if (TrackingId === 'circumference') this.props.GetCircumferenceChart({ BodyAreaId, ChartEndDate, ChartStartDate, ClientId }).then(() => this.setState({ ChartEndDate, ChartStartDate, TimePeriod }));
        else if (TrackingId === 'musclemass') this.props.GetMuscleMassChart({ ChartEndDate, ChartStartDate, ClientId }).then(() => this.setState({ ChartEndDate, ChartStartDate, TimePeriod }));
    }

    onViewBodyFat = () => {
        var { ClientId } = this.props.match.params;
        var { BodyAreaId, PageNo, PageSize, TimePeriod } = this.state;

        var ChartEndDate, ChartStartDate;
        if (TimePeriod === '1W') { ChartEndDate = moment().format('YYYY-MM-DD'); ChartStartDate = moment().subtract(1, 'weeks').format('YYYY-MM-DD') }
        else if (TimePeriod === '1M') { ChartEndDate = moment().format('YYYY-MM-DD'); ChartStartDate = moment().subtract(4, 'weeks').format('YYYY-MM-DD') }
        else if (TimePeriod === '1Y') { ChartEndDate = moment().format('YYYY-MM-DD'); ChartStartDate = moment().subtract(1, 'years').format('YYYY-MM-DD') }

        this.props.ViewBodyFatProgress({ BodyGoalTypeId: BodyAreaId, ChartEndDate, ChartStartDate, ClientId, TrackingPageNo: PageNo, TrackingPageSize: PageSize }).then(() => {
            if (!this.props.TryingViewBodyGoalProgressError) this.setState({ Loading: false });
        });
    }

    onViewBodyWeight = () => {
        var { ClientId } = this.props.match.params;
        var { PageNo, PageSize, TimePeriod } = this.state;

        var ChartEndDate, ChartStartDate;
        if (TimePeriod === '1W') { ChartEndDate = moment().format('YYYY-MM-DD'); ChartStartDate = moment().subtract(1, 'weeks').format('YYYY-MM-DD') }
        else if (TimePeriod === '1M') { ChartEndDate = moment().format('YYYY-MM-DD'); ChartStartDate = moment().subtract(4, 'weeks').format('YYYY-MM-DD') }
        else if (TimePeriod === '1Y') { ChartEndDate = moment().format('YYYY-MM-DD'); ChartStartDate = moment().subtract(1, 'years').format('YYYY-MM-DD') }

        this.props.ViewBodyWeightProgress({ ChartEndDate, ChartStartDate, ClientId, TrackingPageNo: PageNo, TrackingPageSize: PageSize }).then(() => {
            if (!this.props.TryingViewBodyGoalProgressError) this.setState({ Loading: false });
        });
    }

    onViewCircumference = () => {
        var { ClientId } = this.props.match.params;
        var { BodyAreaId, PageNo, PageSize, TimePeriod } = this.state;

        var ChartEndDate, ChartStartDate;
        if (TimePeriod === '1W') { ChartEndDate = moment().format('YYYY-MM-DD'); ChartStartDate = moment().subtract(1, 'weeks').format('YYYY-MM-DD') }
        else if (TimePeriod === '1M') { ChartEndDate = moment().format('YYYY-MM-DD'); ChartStartDate = moment().subtract(4, 'weeks').format('YYYY-MM-DD') }
        else if (TimePeriod === '1Y') { ChartEndDate = moment().format('YYYY-MM-DD'); ChartStartDate = moment().subtract(1, 'years').format('YYYY-MM-DD') }

        this.props.ViewCircumferenceProgress({ BodyAreaId, ChartEndDate, ChartStartDate, ClientId, TrackingPageNo: PageNo, TrackingPageSize: PageSize }).then(() => {
            if (!this.props.TryingViewBodyGoalProgressError) this.setState({ Loading: false });
        });
    }

    onViewMuscleMass = () => {
        var { ClientId } = this.props.match.params;
        var { PageNo, PageSize, TimePeriod } = this.state;

        var ChartEndDate, ChartStartDate;
        if (TimePeriod === '1W') { ChartEndDate = moment().format('YYYY-MM-DD'); ChartStartDate = moment().subtract(1, 'weeks').format('YYYY-MM-DD') }
        else if (TimePeriod === '1M') { ChartEndDate = moment().format('YYYY-MM-DD'); ChartStartDate = moment().subtract(4, 'weeks').format('YYYY-MM-DD') }
        else if (TimePeriod === '1Y') { ChartEndDate = moment().format('YYYY-MM-DD'); ChartStartDate = moment().subtract(1, 'years').format('YYYY-MM-DD') }

        this.props.ViewMuscleMassProgress({ ChartEndDate, ChartStartDate, ClientId, TrackingPageNo: PageNo, TrackingPageSize: PageSize }).then(() => {
            if (!this.props.TryingViewBodyGoalProgressError) this.setState({ Loading: false });
        });
    }

    renderChart = () => {
        var { t } = this.props;
        var { Device, BodyGoal: { Chart, CurrentStatus: { SubTitle, Title, Units } } } = this.props;
        var { ChartEndDate, ChartStartDate, TimePeriod: StateTimePeriod } = this.state;

        var PreMadeDates = [
            { TimePeriod: '1W', TimePeriodName: t('timeperiod_name_one_week') },
            { TimePeriod: '1M', TimePeriodName: t('timeperiod_name_one_month') },
            { TimePeriod: '1Y', TimePeriodName: t('timeperiod_name_one_year') }
        ];

        var JustifyContent = Device === 'ipad' || Device === 'laptop' ? 'flex-start' : 'center';
        var TextAlign = Device === 'ipad' || Device === 'laptop' ? 'left' : 'center';

        return (
            <BodyGoalChartContainer className="BodyGoalChartContainer">
                <BodyGoalChartHeaderContainer className="BodyGoalChartHeaderContainer">
                    <BodyGoalChartHeaderTitleContainer className="BodyGoalChartHeaderTitleContainer">
                        <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent={JustifyContent} NoMargin Text={Title} TextAlign={TextAlign} WordBreak="keep-all" />

                        <Spacer Size="extra-extra-small" />
                    
                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent={JustifyContent} NoMargin Text={SubTitle} TextAlign={TextAlign} WordBreak="keep-all" />
                    </BodyGoalChartHeaderTitleContainer>
                    <BodyGoalChartHeaderDateContainer className="BodyGoalChartHeaderDateContainer">
                        <ChartDatesPreMadeContainer className="ChartDatesPreMadeContainer">
                            {
                                PreMadeDates.map(({ TimePeriod, TimePeriodName }, TimePeriodIndex) => {
                                    var Selected = TimePeriod === StateTimePeriod;

                                    return (
                                        <TimePeriodContainer className="TimePeriodContainer" key={TimePeriodIndex}>
                                            <TimePeriodCheckmark onClick={() => this.onSelectTimePeriod({ TimePeriod })} Selected={Selected} />

                                            <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" OnClick={() => this.onSelectTimePeriod({ TimePeriod })} Text={TimePeriodName} TextAlign="left" />
                                        </TimePeriodContainer>
                                    );
                                })
                            }
                        </ChartDatesPreMadeContainer>
                        <ChartDateCustomContainer className="ChartDateCustomContainer" onClick={() => this.onToggleShowChooseDatesModal(true)} Selected={!StateTimePeriod}>
                            <ChartDateCustomTextContainer className="ChartDateCustomTextContainer">
                                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('custom_date_range')}:`} TextAlign="left" WordBreak="keep-all" />&nbsp;
                                {
                                    StateTimePeriod ?
                                    <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('none_with_first_char_uppercase')} TextAlign="left" WordBreak="keep-all" />
                                :
                                    <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${moment(ChartStartDate).format('[WeeklyViewDayHeader]')} - ${moment(ChartEndDate).format('[WeeklyViewDayHeader]')}`} TextAlign="left" WordBreak="keep-all" />
                                }
                            </ChartDateCustomTextContainer>

                            <CalendarIcon ImageSrc={Calendar} />
                        </ChartDateCustomContainer>
                    </BodyGoalChartHeaderDateContainer>
                </BodyGoalChartHeaderContainer>

                <StyledHR />

                <BodyGoalLineGraphContainer className="BodyGoalLineGraphContainer">
                    <LineGraph
                        EndDate={ChartEndDate}
                        Goals={Chart.Goals}
                        Increment={Chart.Increment}
                        Maximum={Chart.Maximum}
                        Minimum={Chart.Minimum}
                        Measurements={Chart.Measurements}
                        StartDate={ChartStartDate}
                        TimePeriod={StateTimePeriod}
                        Units={Units}
                    />

                    <Spacer Size="small" />

                    <ButtonGroup Buttons={[{ BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-astronaut', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onToggleShowAddMeasurementsModal({ ShowAddMeasurementsModal: true }), Title: t('track_progress') }]} />
                </BodyGoalLineGraphContainer>
            </BodyGoalChartContainer>
        );
    }

    renderCurrentProgress = () => {
        var { t } = this.props;
        var { CompletionStatus, CurrentValue, GoalMax, GoalMin, ProgressLine, Units } = this.props.BodyGoal.CurrentStatus;

        var CompletionColor = CompletionStatus === 'low' ? 'abel-blue' : CompletionStatus === 'high' ? 'red-bittersweet' : 'green';

        return (
            <CurrentProgressContainer className="CurrentProgressContainer">
                <CurrentTextContainer>
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('current')} TextAlign="left" WhiteSpace="nowrap" />
                    <PageText FontColor={CompletionColor} FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${CurrentValue} ${Units}`} TextAlign="left" WhiteSpace="nowrap" />
                </CurrentTextContainer>

                <StyledLineBar
                    CompletionStatus={CompletionStatus}
                    CurrentValue={CurrentValue}
                    GoalMax={GoalMax}
                    GoalMin={GoalMin}
                    HideCurrent
                    ProgressLine={ProgressLine}
                    Units={Units}
                />
            </CurrentProgressContainer>
        );
    }

    renderNoMeasurements = () => {
        var { t } = this.props;

        return (
            <NoMeasurementsContainer>
                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('Progress_BodyGoal_NoMeasurements')} TextAlign="center" />

                <Spacer Size="medium" />

                <ButtonGroup Buttons={[{ BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-astronaut', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onToggleShowAddMeasurementsModal({ ShowAddMeasurementsModal: true }), Title: t('track_progress') }]} />
            </NoMeasurementsContainer>
        );
    }

    renderShowAddMeasurementsModal = () => {
        var { ShowAddMeasurementsModal } = this.state;

        if (ShowAddMeasurementsModal) {
            var { ClientId, TrackingId } = this.props.match.params;
            var { BodyGoal: { AddMeasurementDetails: { GoalMaxMeasurement, GoalMinMeasurement, Measurement, Units }, CurrentStatus } } = this.props;
            var { FromEditing, PageNo, PageSize } = this.state;

            var { ChartEndDate, ChartStartDate } = this.onBuildChartDates();

            // Get TrackingId From Url Instead Of State, Because of Caliper3 And Caliper7 Converting To BodyFat
                TrackingId = TrackingId.toLowerCase();

            var TrackingPageNo = 1;
            var TrackingPageSize = (PageNo * PageSize);

            // Current, GoalMax, GoalMin, HasGoals, ScreenNumber, TrackingPageNo, TrackingPageSize, Units
            var DefaultAddMeasurementDetails = { ChartEndDate, ChartStartDate, Current: Measurement, GoalMax: GoalMaxMeasurement, GoalMin: GoalMinMeasurement, HasGoals: 0, ScreenNumber: 1, TrackingPageNo, TrackingPageSize, Units }

            if (TrackingId === 'caliper3' || TrackingId === 'caliper7') {
                // eslint-disable-next-line
                if (Object.keys(FromEditing).length > 0) var { Measurements, TrackingHistoryId } = FromEditing;
                // eslint-disable-next-line
                else var { Measurements } = this.props.BodyGoal.AddMeasurementDetails;

                var BodyAreas = Measurements.map(({ BodyAreaId, BodyAreaName, Measurement: Current, SevenPoint, ThreePoint, Units }) => ({ BodyAreaId, BodyAreaName, Current, SevenPoint, ThreePoint, Units }));

                var SevenPoint = {};
                var ThreePoint = {};

                var BodyGoalTypeId = TrackingId === 'caliper3' ? 3 : 4;

                if (TrackingId === 'caliper3') ThreePoint = { BodyGoalTypeId, GoalMax: GoalMaxMeasurement, GoalMin: GoalMinMeasurement, HasGoals: 0, Units };
                else if (TrackingId === 'caliper7') SevenPoint = { BodyGoalTypeId, GoalMax: GoalMaxMeasurement, GoalMin: GoalMinMeasurement, HasGoals: 0, Units };

                DefaultAddMeasurementDetails = {
                    BodyAreas,
                    BodyGoalTypeId,
                    CalculatedBodyFat: 0,
                    CaliperType: TrackingId,
                    ChartEndDate, ChartStartDate,
                    ScreenNumber: 2, 
                    SelectedBodyAreas: BodyAreas,
                    SelectedBodyAreasIndex: Measurements.map(({ BodyAreaId }) => +BodyAreaId),
                    SevenPoint, ThreePoint,
                    TrackingPageNo, TrackingPageSize, TrackingHistoryId
                }

                TrackingId = 'caliper';
            }
            else if (TrackingId === 'circumference') {
                // eslint-disable-next-line
                var { BodyGoalAreaId, Title, Units } = CurrentStatus;

                DefaultAddMeasurementDetails = {
                    BodyAreas: [{ BodyAreaId: BodyGoalAreaId, BodyAreaName: Title, HasGoals: 0 }],
                    ChartEndDate, ChartStartDate,
                    ScreenNumber: 2, 
                    SelectedBodyAreas: [{ BodyAreaId: BodyGoalAreaId, BodyAreaName: Title, Current: Measurement, GoalMax: GoalMaxMeasurement, GoalMin: GoalMinMeasurement, HasGoals: 0, Units }],
                    SelectedBodyAreasIndex: [ BodyGoalAreaId ],
                    TrackingPageNo, TrackingPageSize, Units
                }
            }

            return (
                <AddMeasurementsModal
                    ClientId={ClientId}
                    OnAddMeasurement={this.onAddMeasurement}
                    OnHide={() => this.onToggleShowAddMeasurementsModal({ ShowAddMeasurementsModal: false })}
                    Show={ShowAddMeasurementsModal}
                    TrackingType={{ ...DefaultAddMeasurementDetails, TrackingId }}
                />
            );
        }
    }

    renderShowChooseDatesModal = () => {
        var { ChartEndDate, ShowChooseDatesModal, ChartStartDate } = this.state;

        if (ShowChooseDatesModal) {
            return (
                <ChooseDates
                    IsDateRange
                    OnHide={() => this.onToggleShowChooseDatesModal(false)}
                    OnSelectDates={({ EndDate, StartDate }) => this.onSelectCustomDates({ EndDate, StartDate })}
                    RequiredDate
                    SelectedDates={[]}
                    StartDate={ChartEndDate}
                    EndDate={ChartStartDate}
                    Show={ShowChooseDatesModal}
                />
            );
        }
    }

    renderShowDeleteTrackingHistoryRecordModal = () => {
        var { ShowDeleteTrackingHistoryRecordModal } = this.state;

        if (ShowDeleteTrackingHistoryRecordModal) {
            var { t } = this.props;

            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onDeleteTrackingHistoryRecord}
                    BottomButtonText={t('delete_with_first_char_uppercase')}
                    Information={t('Progress_Delete_Tracking_Record_Warning')}
                    OnHide={() => this.onToggleShowDeleteTrackingHistoryRecordModal({ ShowDeleteTrackingHistoryRecordModal: false })}
                    Show={ShowDeleteTrackingHistoryRecordModal}
                    Size="small"
                    TextAlign="center"
                />
            );
        }
    }

    renderShowEditTrackingHistoryRecordModal = () => {
        var { t } = this.props;
        var { ShowEditTrackingHistoryRecordModal, TrackingHistoryDetails: { Measurement } } = this.state;

        if (ShowEditTrackingHistoryRecordModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonDisabled={!+Measurement}
                    BottomButtonOnClick={this.onEditTrackingHistoryRecord}
                    BottomButtonText={t('save_with_first_char_uppercase')}
                    InformationRenderer={this.renderShowEditTrackingHistoryRecordModalInfo}
                    OnHide={() => this.onToggleShowEditTrackingHistoryRecordModal({ ShowEditTrackingHistoryRecordModal: false })}
                    Show={ShowEditTrackingHistoryRecordModal}
                    Size="medium"
                    TextAlign="center"
                />
            );
        }
    }

    renderShowEditTrackingHistoryRecordModalInfo = () => {
        var { t } = this.props;
        var { DateFormat, TimeFormat, TrackingHistoryDetails: { Measurement, MeasurementDate, TrackingHistoryIndex, Units } } = this.state;
        var { Measurement: CurrentMeasurement } = this.props.BodyGoal.TrackingHistory.TrackingHistory[TrackingHistoryIndex];

        return (
            <EditGoalContainer>
                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('edit_with_first_char_uppercase')} TextAlign="center" />

                <Spacer Size="small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('current_measurement')} TextAlign="center" />

                <Spacer Size="extra-extra-small" />

                <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="center" NoMargin Text={`${CurrentMeasurement} ${Units}`} TextAlign="center" />

                <Spacer Size="small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('new_measurement')} TextAlign="center" />

                <Spacer Size="extra-extra-small" />
                
                <StyledGoal
                    FontColor="blue-astronaut"
                    FontFamily="semibold"
                    FontSize="medium-3"
                    NoLabel
                    NoMargin
                    OnBlur={this.onBlurMeasurement}
                    OnChange={this.onChangeMeasurement}
                    Placeholder="0"
                    Size="medium"
                    Type="text"
                    Value={Measurement}
                />

                <Spacer Size="small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('measurement_date')} TextAlign="center" />

                <Spacer Size="extra-extra-small" />

                <DateTime
                    dateFormat={DateFormat}
                    timeFormat={TimeFormat}
                    onChange={this.onChangeMeasurementDate}
                    value={moment(MeasurementDate)}
                />
            </EditGoalContainer>
        );
    }

    renderTrackingHistory = () => {
        var { t } = this.props;
        var { BodyGoal: { TrackingHistory: { IsMoreResults, TotalRecords, TrackingHistory } }, TryingGetBodyGoalTrackingHistory } = this.props;
        var { ActiveSearch, PageNo, PageSize } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('ProgressSection_Tracking_history')} TextAlign="center" />

                <Spacer Size="small" />

                <ViewCount>
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('items_count_viewing')} />
                    &nbsp;
                    <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`1 - ${(PageNo * PageSize) < TotalRecords ? (PageNo * PageSize) : TotalRecords}`} />
                    &nbsp;
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('of')} />
                    &nbsp;
                    <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`${TotalRecords}`} />
                    &nbsp;
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('measurements').toLowerCase()} />
                </ViewCount>

                <Spacer Size="small" />

                <TrackingHistoryContainer>
                    <TrackingHistoryRowBodyGoal NoBorder>
                        <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" NoMargin Text={t('Date_str')} TextAlign="center" />
                        <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" NoMargin Text={t('measurement')} TextAlign="center" />
                    </TrackingHistoryRowBodyGoal>

                    <StyledLoadMore
                        ActiveSearch={ActiveSearch}
                        CompletedFirstSearch
                        IsLoading={TryingGetBodyGoalTrackingHistory}
                        ItemDescriptionType={t('measurements').toLowerCase()}
                        ItemId="TrackingHistoryId"
                        ItemName="Measurement"
                        ItemRenderer={this.renderTrackingHistoryList}
                        Items={TrackingHistory}
                        HasMoreItems={!!+IsMoreResults}
                        HideViewCount
                        LoadMore={() => this.onLoadTrackingHistory(true)}
                        NoItemsText={t('search_noresults')}
                        PageNo={PageNo}
                        PageSize={PageSize}
                        TotalRecords={TotalRecords}
                    />
                </TrackingHistoryContainer>
            </>
        );
    }

    renderTrackingHistoryList = ({ Item: { Measurement, Measurements, MeasurementDate, TrackingHistoryId, Units, UpdateDate }, ItemIndex }) => {
        var { TrackingId: UrlTrackingId } = this.props.match.params;
        var { Device, BodyGoal: { TrackingHistory: { TotalRecords } } } = this.props;
        var { TrackingId } = this.state;

        MeasurementDate = MeasurementDate || UpdateDate;

        // Can Always Delete All Records, Unless It Is Last BodyWeight Record
            var CanDelete = 1;
            if (TrackingId === 'bodyweight' && +TotalRecords === 1) CanDelete = 0;

        // Edit Tracking History Opens Single Popup For All Types, Except Calipers (which open add measurements popup due to multiple calipers needing to be provided)
            var EditFunc = () => this.onToggleShowEditTrackingHistoryRecordModal({ ShowEditTrackingHistoryRecordModal: true, TrackingHistoryDetails: { Measurement, MeasurementDate, TrackingHistoryId, TrackingHistoryIndex: ItemIndex, Units } });
            if (UrlTrackingId.toLowerCase() === 'caliper3' || UrlTrackingId.toLowerCase() === 'caliper7') EditFunc = () => this.onToggleShowAddMeasurementsModal({ FromEditing: { Measurements, TrackingHistoryId }, ShowAddMeasurementsModal: true });

        return (
            <TrackingHistoryRowBodyGoal key={TrackingHistoryId} ColumnCount={2}>
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" NoMargin Text={moment(MeasurementDate).format('[TrackingHistory]')} TextAlign="center" />
                
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" NoMargin Text={`${Measurement} ${Units}`} TextAlign="center" />
                
                <TrackingHistoryActionsContainer className="TrackingHistoryActionsContainer">
                    <ActionIcon ImageSrc={(Device === 'ipad' || Device === 'laptop') ? Edit_Light : Edit_Dark} ImageSrcHover={Edit_Dark} OnClick={EditFunc} />
                    
                    {!!CanDelete && <ActionIcon ImageSrc={(Device === 'ipad' || Device === 'laptop') ? Delete_X_Gray : Delete_X} ImageSrcHover={Delete_X} OnClick={() => this.onToggleShowDeleteTrackingHistoryRecordModal({ ShowDeleteTrackingHistoryRecordModal: true, TrackingHistoryDetails: { TrackingHistoryId, TrackingHistoryIndex: ItemIndex } })} />}
                </TrackingHistoryActionsContainer>
            </TrackingHistoryRowBodyGoal>
        );
    }

    render() {
        if (this.state.Loading || this.props.TryingViewBodyGoalProgress) return <Loading />;

        var { ClientDetails: { ClientId, DisplayName }, BodyGoal: { CurrentStatus: { StartValue, Title } }, TryingAddMeasurements, TryingDeleteBodyGoalMeasurement, TryingViewBodyGoalProgressChart } = this.props;

        return (
            <>
                {(TryingAddMeasurements || TryingDeleteBodyGoalMeasurement || TryingViewBodyGoalProgressChart) && <Loading />}

                <Page
                    renderBreadcrumbs={[
                        { Name: `${DisplayName}`, LinkURL: `client/${ClientId}`, Props: { SelectedTab: 'Progress' }},
                        { Name: Title }
                    ]}
                >
                    <PageText FontFamily="medium" FontSize="large" NoMargin Text={Title} />

                    <Spacer Size="large" />
                    {
                        !!+StartValue ?
                        <>
                            {this.renderCurrentProgress()}

                            <Spacer Size="medium" />

                            {this.renderChart()}

                            <Spacer Size="medium" />

                            {this.renderTrackingHistory()}
                        </>
                    :
                        this.renderNoMeasurements()
                    }
                </Page>

                {this.renderShowAddMeasurementsModal()}
                {this.renderShowChooseDatesModal()}
                {this.renderShowDeleteTrackingHistoryRecordModal()}
                {this.renderShowEditTrackingHistoryRecordModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        UserDetails: state.Auth.UserDetails,

        Device: state.Window.Device,
        ClientDetails: state.Client.ClientDetails,
        
        BodyGoal: state.Progress.BodyGoal,

        TryingAddMeasurements: state.Progress.TryingAddMeasurements,
        TryingAddMeasurementsError: state.Progress.TryingAddMeasurementsError,
        TryingDeleteBodyGoalMeasurement: state.Progress.TryingDeleteBodyGoalMeasurement,
        TryingDeleteBodyGoalMeasurementError: state.Progress.TryingDeleteBodyGoalMeasurementError,
        TryingGetBodyGoalTrackingHistory: state.Progress.TryingGetBodyGoalTrackingHistory,
        TryingGetBodyGoalTrackingHistoryError: state.Progress.TryingGetBodyGoalTrackingHistoryError,
        TryingViewBodyGoalProgress: state.Progress.TryingViewBodyGoalProgress,
        TryingViewBodyGoalProgressError: state.Progress.TryingViewBodyGoalProgressError,
        TryingViewBodyGoalProgressChart: state.Progress.TryingViewBodyGoalProgressChart,
        TryingViewBodyGoalProgressChartError: state.Progress.TryingViewBodyGoalProgressChartError
    };
};

export default withTranslation()(connect(mapStateToProps, { AddBodyMeasurements_BodyFat, AddBodyMeasurements_BodyWeight, AddBodyMeasurements_Circumference, AddBodyMeasurements_MuscleMass, ClearBodyGoal, ClearClientDetails, DeleteBodyMeasurement_BodyFat, DeleteBodyMeasurement_BodyWeight, DeleteBodyMeasurement_Circumference, DeleteBodyMeasurement_MuscleMass, GetBodyFatChart, GetBodyWeightChart, GetCircumferenceChart, GetMuscleMassChart, GetBodyFatTrackingHistory, GetBodyWeightTrackingHistory, GetCircumferenceTrackingHistory, GetMuscleMassTrackingHistory, ViewBodyFatProgress, ViewBodyWeightProgress, ViewCircumferenceProgress, ViewMuscleMassProgress } )(BodyGoalComponent));