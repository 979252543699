import styled from 'styled-components';
import { device } from '../../Config/device';

import { Link } from 'react-router-dom';

import BodyMuscles from '../../Components/BodyMuscles';
import Dropdown from '../../Components/Dropdown';
import Icon from '../../Components/Icon';
import Image from '../../Components/Image';
import Input from '../../Components/Form/Input';
import InputLabelSideBySide from '../../Components/Form/InputLabelSideBySide';
import LoadMore from '../../Components/Lists/LoadMore';
import LineBar from '../../Components/Charts/ProgressBars/Line';

import Checkmark from '../../Assets/Icons/Checkmark_Selected.png';

export const AddEnduranceModalInfoContainer = styled.div``;

export const BodyGoalChart = styled.div``;

export const BodyGoalContainer = styled.div`
    align-items: stretch;
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: 15px 25px;
`;

export const BodyGoalHeaderContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const BodyGoalProgressContainer = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
`;

export const BodyGoalsContainer = styled.div`
    align-items: center;
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

    @media ${device.minwidth.ipad} {
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
        // grid-template-columns: 1fr;
    }
`;

export const BodyGoalTitleContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
`;

export const CurrentStatusBox = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 7px;
`;

export const CurrentStatusContainer = styled.div`
    align-items: center;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: ${props => !!props.IsOnlyCurrent ? '1fr' : 'repeat(2, 1fr)'};
    justify-content: center;
`;

export const EnduranceTemplateItem = styled.div`
${
    props => props.Selected ? `color: var(--blue-abel);`
:
    props => props.Disabled ? `color: var(--gray);`
:
    null
}

    :not(:last-child) {
        margin-bottom: 5px;
    }

    ${props => !props.Disabled &&
        `
            @media(hover:hover) {
                :hover {
                    color: var(--blue-abel);
                    cursor: pointer;
                }
            }
        `
    }
`;

export const EnduranceTemplateList = styled.div``;

export const FavoriteExerciseCompletionContainer = styled.div`
    max-width: 200px;
`;

export const FavoriteExerciseContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const ItemContainer = styled.div`
    border: 2px solid transparent;
    height: 110px;
    margin-bottom: 15px;
    margin-left: 15px;
    width: 110px;

    @media(hover:hover) {
        :hover {
            border: 2px solid var(--abel-blue);
            border-radius: 50%;
            cursor: pointer;
        }
    }
`;

export const ListContainer = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;

    @media ${device.maxwidth.mobile} {
        border-top: 2px solid var(--white-concrete);
        margin-top: 20px;
        padding: 15px 0;
    }

    @media ${device.minwidth.ipad} {
        border-left: 2px solid var(--white-concrete);
        margin-left: 20px;
        padding: 0 15px;
    }
`;

export const ListItemsContainer1 = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

export const ListItemsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: -15px;
    margin-left: -15px;
`;

export const NESContainer2 = styled.div`
    align-items: center;
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
`;

export const NESInnerContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    @media ${device.minwidth.ipad} {
        flex-direction: row;
    }
`;

export const PlusIcon = styled.img`
    width: 35px;
`;

export const PlusItemContainer = styled.div`
    align-items: center;
    border: 2px dashed var(--white-concrete);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;

    @media(hover:hover) {
        :hover {
            border: 2px solid transparent;
        }
    }
`;

export const StyledDropdown = styled(Dropdown)`
    margin: 0 auto;
    max-width: 350px;
    width: 100%;

    ${props => props.ForceClose === false ? 'z-index: 1001;' : null};

    ${
        props => props.IsMobile ?
        `
            > div { max-height: none; }
        `
    :
        `
            ${props.Height ? `height: ${props.Height + 45 + 15}px;` : ''}
            margin: 0 auto;
            width: 350px
        `
    }
`;

export const StyledHR = styled.div`
    background-color: var(--white-concrete);
    border-radius: 10px;
    height: 2px;
    margin: 10px 0;
    width: 100%;
`;

export const TotalContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    > svg {
        height: 110px;
        width: 110px;
    }
`;









// ENDURANCE / EXERCISE DETAIL

export const AccordionsContainer = styled.div`
    align-items: start;
    display: flex;
    flex-direction: row;
    justify-content: center;

    > div {
        width: 100%;
    }

    @media ${device.minwidth.ipad} {
        > div:first-child {
            margin-right: 15px;
        }
    }

    @media ${device.maxwidth.mobile} {
        align-items: center;
        flex-direction: column;

        > div:first-child {
            margin-bottom: 15px;
        }
    }
`;

export const ChartContainer = styled.div``;

export const Container = styled.div``;

export const DetailContainer = styled.div`
    align-items: stretch;
    background-color: var(--white-concrete);
    // border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 15px;
`;

export const EditGoalContainer = styled.div``;

export const ExerciseImageContainer = styled.div`
    ${props => props.NoBorder ? '' : 'border: 1px solid var(--white-concrete);'}
    
    border-radius: 10px;
    overflow: hidden;

    > div {
        height: 100%;
    }
`;

export const HeaderContainer = styled.div`
    display: grid;
    grid-gap: 40px;
    grid-template-columns: 1fr;

    @media ${device.minwidth.ipad} {
        grid-gap: 40px;
        grid-template-columns: 1fr 3fr;
    }
`;

export const LineGraphContainer = styled.div`
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    padding: 25px 15px;
`;

export const ProgressContainer = styled.div``;

export const ProgressStatsContainer = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr;
    
    @media ${device.minwidth.ipad} {
        grid-gap: 40px;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }
`;

export const ProgressStatsContainerInner = styled.div`
    background-color: var(--white);
    // border: 2px solid var(--white-concrete);
    border-radius: 10px;
    padding: 10px;
`;

export const StyledBodyMuscles = styled(BodyMuscles)`
    margin: 0 auto;
    padding: 10px;
    width: 100%;
`;

export const StyledExerciseImage = styled(Image)`
    height: 100%;
    object-fit: cover;
    width: 100%;

    @media ${device.minwidth.ipad} {
        max-width: 400px;
    }
`;

export const StyledGoal = styled(Input)`
    margin: 0 auto;
    max-width: 100%;
    width: 150px;

    > input {
        text-align: center;
        text-indent: 0px;
    }
`;

export const StyledInputLabelSideBySide = styled(InputLabelSideBySide)`
    margin: 0 auto;
    width: 200px;
`;

export const StyledLineBar = styled(LineBar)`
    border: none;
`;

export const StyledLoadMore = styled(LoadMore)`
    border: none;
    border-radius: 0px;
`;

export const TrackingHistoryContainer = styled.div`
    margin: 0 auto;
    max-width: 500px;
    width: 100%;
`;

export const TrackingHistoryRow = styled.div`
    align-items: center;
    display: grid;
    grid-template-columns: repeat(${props => props.ColumnCount}, 1fr);
    text-align: center;

    :not(:last-child) {
        margin-bottom: 10px;
    }
`;

export const ViewCount = styled.div`
    text-align: center;
`;




export const ExerciseContainer = styled.div`
    align-items: center;
    border: 2px solid ${props => props.Completed ? 'var(--green)' : 'var(--white-concrete)'};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
`;

export const LinkContainer = styled(Link)`
    color: var(--blue-abel);
    
    @media(hover:hover) {
        :hover {
            color: var(--link-hover);
            cursor: pointer;
        }
    }

    :active {
        color: var(--blue-abel);
    }
`;

export const LinkInnerContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    > svg {
        height: 20px;
        margin-right: 10px;
    }
`;

export const ListContainer2 = styled.div``;

export const ListProgressContainer = styled.div`
    width: 100%;
`;

export const ProgressStatsContainer2 = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr;
    
    @media ${device.minwidth.ipad} {
        grid-template-columns: repeat(3, 1fr);
    }
`;

export const ProgressStatsContainerInner2 = styled.div`
    background-color: var(--white-concrete);
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    padding: 10px;
`;

export const OverallPercentageContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const StyledExerciseLoadMore = styled(LoadMore)`
    border: none;
    border-radius: 0px;

    > div {
        display: grid;
        grid-gap: 15px;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
`;

export const StyledSearchInput = styled(Input)`
    flex: 1;
    margin: 0 auto;
    max-width: 500px;

    > input {
        border: 2px solid var(--white-concrete);
    }
`;

export const TotalProgressCircleContainer = styled.div`
    > svg {
        max-width: 150px;
    }
`;








// BODY GOAL DETAIL

export const ActionIcon = styled(Icon)`
    height: 25px;
    width: 25px;
`;

export const BodyGoalChartContainer = styled.div`
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    padding: 10px 25px;
`;

export const BodyGoalChartHeaderContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media ${device.minwidth.ipad} {
        flex-direction: row;
        justify-content: space-between;
    }
`;

export const BodyGoalChartHeaderDateContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media ${device.maxwidth.mobile} {
        > div:first-child {
            margin-bottom: 10px;
        }
    }

    @media ${device.minwidth.ipad} {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;

        > div:first-child {
            margin-right: 10px;
        }
    }
`;

export const BodyGoalChartHeaderTitleContainer = styled.div`
    @media ${device.maxwidth.mobile} {
        margin-bottom: 15px;
    }
`;

export const BodyGoalLineGraphContainer = styled.div``;

export const CalendarIcon = styled(Image)`
    height: 25px;
`;

export const CalendarInput = styled.input`
    align-items: center;
    background-color: var(--white);
    border: 2px solid var(--white-concrete);
    border-radius: 20px;
    color: var(--blue-astronaut);
    display: flex;
    flex-direction: column;
    font-family: var(--font-family-semibold);
    font-size: var(--font-size-medium-3);
    justify-content: center;
    line-height: 1.25rem;
    margin: 0 auto;
    padding: 5px 10px;
    text-align: center;
    height: var(--input-size-medium);
    outline: none;
    width: 300px;
`;

export const ChartDateCustomContainer = styled.div`
    align-items: center;
    border: 2px solid ${props => props.Selected ? 'var(--blue-abel)' : 'var(--white-concrete)'};
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 10px;

    @media(hover:hover) {
        :hover {
            border-color: var(--blue-abel);
            cursor: pointer;
        }
    }
`;

export const ChartDateCustomTextContainer = styled.div`
    margin-right: 10px;
`;

export const ChartDatesPreMadeContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > div:not(:last-child) {
        margin-right: 5px;
    }
`;

export const CurrentProgressContainer = styled.div`
    align-items: center;
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 25px;

    @media ${device.minwidth.ipad} {
        > div:first-child {
            margin-right: 15px;
        }
    }
`;

export const CurrentTextContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const ExtraHeight = styled.div`
    height: 270px;
`;

export const NoMeasurementsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
`;

export const TimePeriodCheckmark = styled.span`
    ${
        props => props.Selected ?
        `
            background-image: url(${Checkmark});
            background-position: center;
            background-size: 25px;
        `
    :
        `
            background-color: var(--white);
            border: 2px solid var(--gray-light);
        `
    };
    border-radius: 50%;
    display: block;
    height: 25px;
    margin-right: 5px;
    min-width: 25px;
    width: 25px;

    @media(hover:hover) {
        :hover {
            background-image: url(${Checkmark});
            background-position: center;
            background-size: 25px;
            border: none;
            cursor: pointer;
        }
    }
`;

export const TimePeriodContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
`;

export const TrackingHistoryActionsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    > div:first-child {
        margin-right: 10px;
    }
`;

export const TrackingHistoryContainerBodyGoal = styled.div`
    margin: 0 auto;
    max-width: 500px;
    width: 100%;
`;

export const TrackingHistoryRowBodyGoal = styled.div`
    align-items: center;
    display: grid;
    grid-template-columns: 4fr 4fr 1fr;
    padding: 10px 18px;
    text-align: center;

    ${props => !props.NoBorder && `
        border: 2px solid var(--white-concrete);

        :first-child {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
    
        :last-child {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    
        :not(:last-child) {
            border-bottom: none;
        }
    `}
`;