import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ClearAdditionalActivityDetails, ClearSaveData, ClearWorkoutTemplateDetail, GetAdditionalActivityDetails, GetWorkoutTemplateDetail, InsertEditWorkoutTemplateDetail, SaveDataInProps, UploadToPresignedUrl } from '../../Services/Actions';
import history from '../../history';
import moment from 'moment';

import { BETA_SITE_URL } from '../../Config';

import TimePicker from 'rc-time-picker';

import { ActivityPictureContainer, ActivityPicturesContainer, AdditionalActivityItem, AdditionalActivityList, AutoSaveButtonGroup, CircuitContainer, CircuitSeparator, DateTimeContainer, DateTimesContainer, DateTimeUseAllDays, DateTimeUseAllDaysContainer, DeleteSetIcon, HourType, HourTypesContainer, JointEquipmentContainer, JointEquipmentItem, LapContainer, LapDetailsContainer, LapFooterContainer, LapHeaderContainer, LapHeaderContainerLeft, LapHeaderContainerRight, OptionalLine, PseudoCheckbox, StyledActivityImage, StyledAddLap, StyledDropdown, StyledDuration, StyledInputLabelMultiText, StyledIntensities, StyledMultiText, StyledTemplateName, TrainerNoteContainer, TrainerNoteTextArea, UploadContainer, UploadButtonsContainer, UploadButtonsRow, WorkoutExercisesContainer, WorkoutLapsContainer } from './styles';

import ActiveLap from '../../Components/WorkoutLaps/ActiveLap';
import BreakLap from '../../Components/WorkoutLaps/BreakLap';
import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Loading from '../../Components/Loading';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';
import { TemplateExercise as TemplateExerciseComponent } from '../../Components/WorkoutExercise';
import UploadProgressComponent from '../../Components/UploadProgress';
import WorkoutCircuits from '../../Components/WorkoutCircuits';

import AddExercise from '../../Modals/AddExercise';
import WorkoutTemplateFillIn from '../../Modals/WorkoutTemplateFillIn';
import ExerciseDetail from '../../Modals/ExerciseDetail';

import Camera from '../../Assets/Icons/Camera.png';
import DeleteX from '../../Assets/Icons/Delete_X.png';
import Plus from '../../Assets/Icons/Plus.png';

import { CreateUploadToPresignedUrlPromise, getFileExtension, validateFile } from '../../Functions';

const INIT_STATE = {
    ActivityId: null,
    ActivityName: '',
    ActivityPicture: null,
    ActivityPictureId: null,
    ActivityPictures: [],
    AdditionalActivityDuration: 0,
    AdditionalActivities: [],
    CopiedTemplateId: null,
    CustomName: '',
    DisplayingFilter: null,
    DropdownSize: 0,
    Duration: 0,
    HourType: 24,
    ImageTooLarge: false,
    Injuries: [],
    IntensityId: null,
    Intensities: [],
    Loading: true,
    Location: '',
    MaxImageSize: 10000000,
    QuickSearchExerciseName: '',
    RepRange: [],
    SelectedImage: null,
    ShowAddExerciselModal: false,
    ShowCustomName: null,
    ShowDateTimes: null,
    ShowExerciseDetailModal: false,
    ShowExerciseDetailPreview: {},
    ShowLearnMoreModal: false,
    ShowReorderExercises: false,
    Tags: [],
    TemplateId: null,
    TemplateImage: null,
    TemplateName: '',
    Touched: false,
    TrainerNote: '',
    TrainingFocus: [],
    TypingTimeout: 0,
    WorkoutExercises: [],
    WorkoutLaps: { Laps: [] },
    WorkoutType: null,
    WrongImageType: false
}

// var i;
class WorkoutTemplate extends React.Component {
    _isMounted = false;
    _TypingTimeout = 0;

    state = { ...INIT_STATE };

    unloadFunction = () => {
        if (this.state.Touched) localStorage.setItem('AutoSave_WorkoutTemplate', JSON.stringify(this.state));
    }

    componentDidMount() {
        var { TemplateId } = this.props.match.params;
        var RedirectLink = `${BETA_SITE_URL}/workout-library/session`;

        // Editing a session
            if (!!Number.isInteger(TemplateId)) window.location = `${RedirectLink}/${TemplateId}`;
        // Check For Workout To Template, Or Template To Template
            else {
                var { WorkoutToTemplateDetails } = this.props;

                if (Object.entries(WorkoutToTemplateDetails).length) {
                    var { CopiedTemplateId, FromTemplate, WorkoutDetails } = WorkoutToTemplateDetails;
                    // Template to Template
                        if (FromTemplate) RedirectLink += `/${CopiedTemplateId}?createFromExistingTemplate=true`;
                    // Workout To Template
                        else RedirectLink += `/${WorkoutDetails.WorkoutId}?createFromExistingWorkout=true`;
                }

                window.location = RedirectLink;
            }


        // this._isMounted = true;

        // window.onbeforeunload = this.unloadFunction;

        // if (this.props.location.state && !!this.props.location.state.IsReturnData) this.setState({ ...this.props.location.state });
        // else {
        //     var { TemplateId } = this.props.match.params;
        //     TemplateId = +TemplateId || null;

        //     this.props.GetWorkoutTemplateDetail({ TemplateId }).then(() => {
        //         if (!Number.isInteger(TemplateId)) {
        //             var { WorkoutToTemplateDetails } = this.props;
    
        //             // Build Template From Workout Details
        //             if (Object.entries(WorkoutToTemplateDetails).length) {
        //                 // eslint-disable-next-line
        //                 var { AdditionalActivityDetails, CopiedTemplateId, FromTemplate, Injuries = [], RepRange = [], TrainingFocus = [], WorkoutDetails, WorkoutExercises, WorkoutLaps } = WorkoutToTemplateDetails;
        //                 if (FromTemplate) {
        //                     // eslint-disable-next-line
        //                     var { ActivityId, CustomImage, Duration, IntensityId, Location, StartTime, TemplateName, TrainerNote, WorkoutImage, WorkoutType } = WorkoutDetails;
                            
        //                     var AssignedDate = StartTime ? moment(`${moment().format('YYYY-MM-DD')} ${StartTime}`).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm');
        //                     var ShowWorkoutTime = !!StartTime;
        //                     var WorkoutName = TemplateName;
        //                 }
        //                 else {
        //                     // eslint-disable-next-line
        //                     var { ActivityId, AssignedDate, Duration, IntensityId, ShowWorkoutTime, TemplateName, TrainerNote, WorkoutLocation: Location, WorkoutName, WorkoutType } = WorkoutDetails;
        //                 }
    
        //                 var CustomName = WorkoutName;
    
        //                 if (+WorkoutType === 1) {
        //                     WorkoutExercises = WorkoutExercises.map((Circuit, CircuitIndex) => {
        //                         return {
        //                             ...Circuit,
        //                             id: CircuitIndex,
        //                             WorkoutExercises: Circuit.WorkoutExercises.map(({ DataType, ExerciseId, ExerciseImage, ExerciseName, ExerciseType, Notes, Header_Distance, Header_Reps, Header_Seconds, Header_Weight, MaxType, Sets, TestSets }) => {
        //                                 DataType = FromTemplate ? DataType : 'static';
        //                                 MaxType = FromTemplate ? MaxType : 'Progress';
            
        //                                 if (Sets.length) {
        //                                     return { DataType, ExerciseId: +ExerciseId, ExerciseImage, ExerciseName, ExerciseType: +ExerciseType, Notes, Header_Distance, Header_Reps, Header_Seconds, Header_Weight, MaxType,
        //                                         Sets: Sets.map(({ Break, Distance, MaxSet, Reps, Rest, Seconds, Units, Weight }) => {
        //                                             return { Distance: +Distance || null, MaxSet: +MaxSet, Rest: FromTemplate ? +Rest : +Break, Reps: +Reps || null, Seconds: +Seconds || null, Units, Weight: +Weight || null, WorkoutSetId: 0 };
        //                                         }),
        //                                         WorkoutExerciseId: 0
        //                                     };
        //                                 } else if (TestSets.length) {
        //                                     return { DataType, ExerciseId: +ExerciseId, ExerciseImage, ExerciseName, ExerciseType: +ExerciseType, Notes, Header_Distance, Header_Reps, Header_Seconds, Header_Weight, MaxType,
        //                                         Sets: TestSets.map(({ Break, Distance, MaxSet, Reps, Seconds, Units, Weight }) => {
        //                                             return { Distance: +Distance, MaxSet: +MaxSet, Rest: +Break, Reps: +Reps, Seconds: +Seconds, Units, Weight: +Weight, WorkoutSetId: 0 };
        //                                         }),
        //                                         WorkoutExerciseId: 0
        //                                     };
        //                                 } else return [];
        //                             })
        //                         }
        //                     });
    
        //                     WorkoutLaps = { Laps: [] };
        //                 } else if (+WorkoutType === 2) {
        //                     var NewDataType = FromTemplate ? WorkoutLaps.DataType : 'static';
                            
        //                     WorkoutLaps = {
        //                         ActiveType: WorkoutLaps.ActiveType,
        //                         CardioLevel: 1,
        //                         DataType: NewDataType,
        //                         Laps: WorkoutLaps.Laps
        //                     };
    
        //                     WorkoutExercises = [];
        //                 }
    
        //                 var { AdditionalActivities, Intensities } = AdditionalActivityDetails;
        //                 var ActivityName = '';
        //                 var ActivityPicture;
        //                 var ActivityPictures = [];
        //                 var IntensityName = '';
    
        //                 for (i = 0; i < AdditionalActivities.length; i++) {
        //                     if (AdditionalActivities[i].value === ActivityId) {
        //                         ActivityName = AdditionalActivities[i].label;
        //                         ActivityPicture = AdditionalActivities[i].Pictures[0];
        //                         ActivityPictures = AdditionalActivities[i].Pictures;
        //                         break;
        //                     }
        //                 }
    
        //                 for (i = 0; i < Intensities.length; i++) {
        //                     if (Intensities[i].IntensityId === IntensityId) {
        //                         IntensityName = Intensities[i].IntensityName;
        //                         break;
        //                     }
        //                 }
    
        //                 if (WorkoutType !== 2) WorkoutLaps = { Laps: [] };
    
        //                 var ActivityPictureId = ActivityId;
        //                 if (CustomImage) ActivityPicture = WorkoutImage;
    
        //                 this.setState({ ActivityId, ActivityName, ActivityPicture, ActivityPictureId, ActivityPictures, AdditionalActivities, AdditionalActivityDuration: Duration, CopiedTemplateId, CustomName, Duration, Injuries, Intensities, IntensityId, IntensityName, Loading: false, Location, RepRange, ShowCustomName: CustomName !== '' && CustomName !== null, ShowDateTimes: ShowWorkoutTime, StartTime: ShowWorkoutTime ? moment.utc(AssignedDate).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm'), TemplateName: WorkoutName, Touched: true, TrainerNote, TrainingFocus, WorkoutExercises, WorkoutLaps, WorkoutType });
        //                 window.scrollTo(0,0);
        //             }
        //             // From auto-saved template
        //             if (localStorage.getItem('AutoSave_WorkoutTemplate')) {
        //                 var NewState = { ...JSON.parse(localStorage.getItem('AutoSave_WorkoutTemplate')) };
        //                 this.setState({ ...NewState, ActivityPictureId: NewState.ActivityId, ActivityPicture: NewState.ActivityPictures[0], TemplateImage: null });
        //             }
        //             else {
        //                 // eslint-disable-next-line
        //                 var { AdditionalActivityDetails: { AdditionalActivities, Intensities } } = this.props.WorkoutTemplate;
        //                 // eslint-disable-next-line
        //                 var ActivityName = '';
        //                 // eslint-disable-next-line
        //                 var ActivityPicture;
        //                 // eslint-disable-next-line
        //                 var ActivityPictures = [];
        //                 // eslint-disable-next-line
        //                 var IntensityName = '';
    
        //                 for (i = 0; i < AdditionalActivities.length; i++) {
        //                     if (AdditionalActivities[i].value === ActivityId) {
        //                         ActivityName = AdditionalActivities[i].label;
        //                         ActivityPicture = AdditionalActivities[i].Pictures[0];
        //                         ActivityPictures = AdditionalActivities[i].Pictures;
        //                         break;
        //                     }
        //                 }
    
        //                 for (i = 0; i < Intensities.length; i++) {
        //                     if (Intensities[i].IntensityId === IntensityId) {
        //                         IntensityName = Intensities[i].IntensityName;
        //                         break;
        //                     }
        //                 }
    
        //                 // eslint-disable-next-line
        //                 var ActivityPictureId = ActivityId;
        //                 if (CustomImage) ActivityPicture = WorkoutImage;

        //                 this.setState({ ActivityId, ActivityName, ActivityPicture, ActivityPictureId, ActivityPictures, AdditionalActivities, Intensities, IntensityId, IntensityName, Loading: false });
        //                 window.scrollTo(0,0);
        //             }
        //         }
        //         else {
        //             if (!this.props.TryingGetWorkoutTemplateDetailError) {
        //                 // eslint-disable-next-line
        //                 var { AdditionalActivityDetails, TemplateDetails: { ActivityId, CustomImage, CustomName, Duration, Injuries, IntensityId, IsOwner, Location, RepRange, StartTime, Tags, TemplateName, TrainerNote, TrainingFocus, WorkoutImage, WorkoutType }, WorkoutExercises, WorkoutLaps } = this.props.WorkoutTemplate;

        //                 if (!+IsOwner) history.push({ pathname: '/library-workouts', search: '?tab=workout-templates' });
        //                 else {
        //                     // eslint-disable-next-line
        //                     var { AdditionalActivities, Intensities } = AdditionalActivityDetails;
        //                     // eslint-disable-next-line
        //                     var ActivityName = '';
        //                     // eslint-disable-next-line
        //                     var ActivityPictures = [];
        //                     // eslint-disable-next-line
        //                     var IntensityName = '';

        //                     for (i = 0; i < AdditionalActivities.length; i++) {
        //                         if (AdditionalActivities[i].value === ActivityId) {
        //                             ActivityName = AdditionalActivities[i].label;
        //                             ActivityPictures = AdditionalActivities[i].Pictures;
        //                             break;
        //                         }
        //                     }

        //                     for (i = 0; i < Intensities.length; i++) {
        //                         if (Intensities[i].IntensityId === IntensityId) {
        //                             IntensityName = Intensities[i].IntensityName;
        //                             break;
        //                         }
        //                     }

        //                     if (WorkoutType === 1 || WorkoutType === 3) WorkoutLaps = { ActiveType: null, CardioLevel: null, DataType: null, Laps: [] };
        //                     if (WorkoutType === 2) WorkoutLaps.Laps = [].concat(...WorkoutLaps.Laps.map(WL => WL.Laps.map(Lap => ({ ...Lap, LapType: WL.Type }))));

        //                     // eslint-disable-next-line
        //                     var ActivityPictureId = ActivityId;
        //                     var TemplateImage = null;
        //                     if (CustomImage) {
        //                         ActivityPictureId = 'custom';
        //                         TemplateImage = WorkoutImage;
        //                     }

        //                     this.setState({ ActivityId, ActivityName, ActivityPicture: WorkoutImage, ActivityPictureId, ActivityPictures, AdditionalActivities, AdditionalActivityDuration: Duration, CustomName, Duration, Injuries, Intensities, IntensityId, IntensityName, Location, Loading: false, RepRange, ShowCustomName: CustomName !== '' && CustomName !== null, ShowDateTimes: StartTime !== '' && StartTime !== null, StartTime: StartTime ? moment(`${moment().format('YYYY-MM-DD')} ${StartTime}`).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm'), Tags, TemplateId, TemplateImage, TemplateName, TrainerNote, TrainingFocus, WorkoutExercises, WorkoutLaps, WorkoutType });
        //                 }
        //             } else this.setState({ Loading: false });
        //         }
        //     });
        // }
    }
    
    componentWillUnmount() {
        this._isMounted = false;

        window.onbeforeunload = null;

        if (this.state.TemplateId) this.props.ClearWorkoutTemplateDetail();
        if (this.props.RetrievedAdditionalActivitySettings) this.props.ClearAdditionalActivityDetails();

        this.props.ClearSaveData();
    }

    onAddBreak = LapIndex => {
        var Laps = [ ...this.state.WorkoutLaps.Laps ];

        Laps.splice(LapIndex + 1, 0, { ...Laps[LapIndex], Active: 0, ActiveUnits: null, BreakTime: 60, LapType: 'Break', Rest: 0, Speed: 0, WorkoutLapId: null });

        var LapNumber = 1;
        Laps = Laps.map(Lap => ({ ...Lap, LapNumber: Lap.LapType === 'Active' ? LapNumber++ : 0 }));

        this.setState({ WorkoutLaps: { ...this.state.WorkoutLaps, Laps } });
    }

    onAddExercises = SelectedExercises => {
        var { t } = this.props;
        var Units = '';
        if (this.state.TemplateId) Units = this.props.WorkoutTemplate.TemplateDetails.Units;
        else Units = this.props.UserDetails.PreferredMeasuringSystem.toLowerCase();

        var WorkoutExercises = [ ...this.state.WorkoutExercises ];

        var NewExercise = {};
        SelectedExercises.map(({ ExerciseId, ExerciseImage, ExerciseName, ExerciseType }) => {
            NewExercise = { ExerciseId, ExerciseImage, ExerciseName, Notes: { Coach: '' }, ExerciseType, MaxType: 'Progress', Sets: [], WorkoutExerciseId: 0 };

            if (ExerciseType === 1) { NewExercise.DataType = 'dynamic-reps'; NewExercise.Sets.push({ MaxSet: 0, Reps: '', Rest: '', Units: t('Reps'), WorkoutSetId: 0 }); }
            else if (ExerciseType === 2) { NewExercise.DataType = 'dynamic-seconds'; NewExercise.Sets.push({ MaxSet: 0, Rest: '', Seconds: '', WorkoutSetId: 0 }); }
            else if (ExerciseType === 3) { NewExercise.DataType = 'dynamic-distance'; NewExercise.Sets.push({ Distance: '', MaxSet: 0, Rest: '', Units: Units === 'imperial' ? 'yards' : 'meters', WorkoutSetId: 0 }); }
            else if (ExerciseType === 4) { NewExercise.DataType = 'dynamic-weight'; NewExercise.Header_Reps = t('Reps'); NewExercise.Sets.push({ MaxSet: 0, Reps: '', Rest: '', Units: Units === 'imperial' ? 'lbs' : 'kg', Weight: '', WorkoutSetId: 0 }); }
            else if (ExerciseType === 5) { NewExercise.DataType = 'dynamic-weight'; NewExercise.Sets.push({ MaxSet: 0, Rest: '', Seconds: '', Units: Units === 'imperial' ? 'lbs' : 'kg', Weight: '', WorkoutSetId: 0 }); }

            WorkoutExercises.push({ CircuitNumber: 0, NumExercises: 1, Type: 'WorkoutExercise', WorkoutExercises: [{ ...NewExercise }] });

            return null;
        });

        this.setState({ WorkoutExercises });
    }

    onAddLap = LapIndex => {
        var { t } = this.props;
        var { ActiveType } = this.state.WorkoutLaps;

        var Laps = [ ...this.state.WorkoutLaps.Laps ];

        // Copy if previous lap is not a break lap && not the first lap
            if (Laps.length && Laps[LapIndex].LapType === 'Active') Laps.splice(LapIndex + 1, 0, { ...Laps[LapIndex], WorkoutLapId: null });
        // Else create new lap with 0 seconds
            else {
                var Units = '';
                if (this.state.TemplateId) Units = this.props.WorkoutTemplate.TemplateDetails.Units;
                else Units = this.props.UserDetails.PreferredMeasuringSystem.toLowerCase();

                var NewActiveUnits = ActiveType === 'Time' ? null : t(Units === 'imperial' ? 'yards' : 'meters').toLowerCase();
                var NewSpeedUnits = Units === 'imperial' ? 'mph' : 'km/h';

                Laps.splice(LapIndex + 1, 0, { ...Laps[LapIndex], Active: 0, ActiveUnits: NewActiveUnits, BreakTime: 0, LapType: 'Active', Rest: 0, Speed: 10, SpeedUnits: NewSpeedUnits, WorkoutLapId: null });
            }

        var LapNumber = 1;
        Laps = Laps.map(Lap => ({ ...Lap, LapNumber: Lap.LapType === 'Active' ? LapNumber++ : 0 }));

        this.setState({ WorkoutLaps: { ...this.state.WorkoutLaps, Laps } });
    }

    onAddSet = ({ CircuitWorkoutExerciseIndex, WorkoutExerciseIndex }) => {
        var WorkoutExercises = [ ...this.state.WorkoutExercises ];

        WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets = [
            ...WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets,
            { ...WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets[WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets.length - 1], MaxSet: 0, WorkoutSetId: 0 }
        ];

        this.setState({ WorkoutExercises });
    }

    onCancelCircuits = () => {
        this.setState({ ShowReorderExercises: false });
    }

    onChangeActiveType = ActiveType => {
        // Used for Endurance Template to change between "Time" && "Distance"
        this.setState({ WorkoutLaps: { ...this.state.WorkoutLaps, ActiveType } });
    }

    onChangeAdditionalActivityDuration = event => {
        this.setState({ AdditionalActivityDuration: event.target.value });
    }

    onChangeCustomName = event => {
        this.setState({ CustomName: event.target.value });
    }

    onChangeDataType = ({ CircuitWorkoutExerciseIndex, DataType, WorkoutExerciseIndex }) => {
        var WorkoutExercises = [ ...this.state.WorkoutExercises ];

        WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].DataType = DataType;

        this.setState({ WorkoutExercises });
    }

    onChangeMaxType = ({ CircuitWorkoutExerciseIndex, MaxType, WorkoutExerciseIndex }) => {
        var WorkoutExercises = [ ...this.state.WorkoutExercises ];

        WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].MaxType = MaxType;

        this.setState({ WorkoutExercises });
    }

    onChangeDateTime = StartTime => {
        this.setState({ StartTime: moment(StartTime).format('YYYY-MM-DD HH:mm') });
    }

    onChangeDuration = event => {
        this.setState({ Duration: event.target.value });
    }

    onChangeEnduranceDataType = DataType => {
        // Used for Endurance Template to change between "dynamic" && "static"
        this.setState({ WorkoutLaps: { ...this.state.WorkoutLaps, DataType } });
    }

    onChangeExerciseNote = ({ CircuitWorkoutExerciseIndex, Note, WorkoutExerciseIndex }) => {
        var WorkoutExercises = [ ...this.state.WorkoutExercises ];

        WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Notes.Coach = Note;

        this.setState({ WorkoutExercises });
    }

    onChangeHourType = HourType => {
        this.setState({ HourType });
    }

    onChangeLocation = event => {
        this.setState({ Location: event.target.value });
    }

    onChangeTemplateName = event => {
        this.setState({ TemplateName: event.target.value });
    }

    onChangeTrainerNote = event => {
        this.setState({ TrainerNote: event.target.value });
    }

    onChangeWorkoutType = WorkoutType => {
        this.setState({ Touched: true, WorkoutType }, () => {
            if (!this.state.AdditionalActivities.length && !this.props.RetrievedAdditionalActivitySettings) {
                this.props.GetAdditionalActivityDetails({ ForWorkoutTemplates: true }).then(({ AdditionalActivities, Intensities }) => {
                    if (!this.state.ActivityId && (WorkoutType === 1 || WorkoutType === 2)) {
                        for (var i = 0; i < AdditionalActivities.length; i++) {
                            var { value: ActivityId } = AdditionalActivities[i];

                            if ((WorkoutType === 1 && ActivityId === 60) || (WorkoutType === 2 && ActivityId === 34)) {
                                var { label: ActivityName, Pictures: ActivityPictures } = AdditionalActivities[i];

                                this.setState({ ActivityId, ActivityName, ActivityPicture: ActivityPictures[0], ActivityPictureId: ActivityId, ActivityPictures, AdditionalActivities, Intensities });
                                break;
                            }
                        }
                    }
                    else this.setState({ AdditionalActivities, Intensities });
                });
            } else if (!this.state.ActivityId) {
                if ((WorkoutType === 1 || WorkoutType === 2)) {
                    var { AdditionalActivities } = this.state;

                    for (var i = 0; i < AdditionalActivities.length; i++) {
                        var { value: ActivityId } = AdditionalActivities[i];

                        if ((WorkoutType === 1 && ActivityId === 60) || (WorkoutType === 2 && ActivityId === 34)) {
                            var { label: ActivityName, Pictures: ActivityPictures } = AdditionalActivities[i];

                            this.setState({ ActivityId, ActivityName, ActivityPicture: ActivityPictures[0], ActivityPictureId: ActivityId, ActivityPictures });
                            break;
                        }
                    }
                }
            }
        });
    }

    onClearAutoSave = ({ ClearState = false }) => {
        localStorage.removeItem('AutoSave_WorkoutTemplate');

        if (ClearState) this.setState({ ...INIT_STATE, Loading: false });
    }

    onDeleteExercise = ({ CircuitWorkoutExerciseIndex, WorkoutExerciseIndex }) => {
        var NewWorkoutExercises = [];
        var WorkoutExercises = [ ...this.state.WorkoutExercises ];

        WorkoutExercises.map((Circuit, index) => {
            NewWorkoutExercises.push({ ...Circuit });

            if (WorkoutExerciseIndex === index) {
                NewWorkoutExercises[NewWorkoutExercises.length - 1].WorkoutExercises = [];

                Circuit.WorkoutExercises.map((WE, WEIndex) => {
                    if (WEIndex !== CircuitWorkoutExerciseIndex) NewWorkoutExercises[NewWorkoutExercises.length - 1].WorkoutExercises.push({ ...WE });
                    
                    return null;
                });
            }

            return null;
        });

        var CircuitNumber = 1;
        var UpdatableCircuits = NewWorkoutExercises.map(Circuit => {
            Circuit.NumExercises = Circuit.WorkoutExercises.length;

            if (Circuit.WorkoutExercises.length === 1) Circuit = { ...Circuit, CircuitNumber: 0, Type: 'WorkoutExercise' };
            else {
                Circuit.CircuitNumber = CircuitNumber;
                CircuitNumber++;
            }

            return Circuit;
        }).filter(Circuit => !!Circuit.NumExercises);

        this.setState({ WorkoutExercises: UpdatableCircuits });
    }

    onDeleteLap = LapIndex => {
        var Laps = [ ...this.state.WorkoutLaps.Laps ];

        Laps = [ ...Laps.slice(0, LapIndex), ...Laps.slice(LapIndex + 1) ];

        var LapNumber = 1;
        Laps = Laps.map((Lap, index) => ({ ...Lap, LapNumber: Lap.LapType === 'Active' ? LapNumber++ : 0, LapOrder: index + 1 }));

        this.setState({ WorkoutLaps: { ...this.state.WorkoutLaps, Laps } });
    }

    onDeleteSet = ({ CircuitWorkoutExerciseIndex, WorkoutExerciseIndex, WorkoutSetIndex }) => {
        var WorkoutExercises = [ ...this.state.WorkoutExercises ];

        // Delete Individual Set
            if (WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets.length > 1) {
                WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets = [ ...WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets.slice(0, WorkoutSetIndex), ...WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets.slice(WorkoutSetIndex + 1) ];

                this.setState({ WorkoutExercises });
            }
        // Deleting last set, so remove full Workout Exercise
            else this.onDeleteExercise({ CircuitWorkoutExerciseIndex, WorkoutExerciseIndex });
    }

    onGetSize = Height => {
        this.setState({ DropdownSize: Height });
    }

    onInsertEditWorkoutTemplate = () => {
        var { ActivityId, ActivityPictureId, AdditionalActivityDuration, CopiedTemplateId, CustomName, Duration, Injuries, IntensityId, Location, RepRange, SelectedImage, ShowCustomName, ShowDateTimes, StartTime, Tags, TemplateId, TemplateName, TrainerNote, TrainingFocus, WorkoutExercises, WorkoutLaps: { ActiveType, CardioLevel, DataType, Laps }, WorkoutType } = this.state;

        var Units = '';
        if (TemplateId) Units = this.props.WorkoutTemplate.TemplateDetails.Units;
        else Units = this.props.UserDetails.PreferredMeasuringSystem.toLowerCase();

        if (WorkoutType === 3) Duration = AdditionalActivityDuration;

        if (!ShowDateTimes) StartTime = '';
        else StartTime = moment(StartTime).format('HH:mm');

        if (!ShowCustomName) CustomName = '';

        var CustomImage = 0;

        var TemplateImage = null;
        if (ActivityPictureId === 'custom') {
            CustomImage = 1;
            if (SelectedImage) TemplateImage = { ContentType: SelectedImage.type, FileName: SelectedImage.name, FileSize: SelectedImage.size };
        }

        // Update WorkoutExercises So That CircuitNumber Is Added Property To Each Exercise
        if (WorkoutType === 1) {
            var NewWorkoutExercises = [];

            for (let i = 0; i < WorkoutExercises.length; i++) {
                let { CircuitNumber, WorkoutExercises: WEArr } = WorkoutExercises[i];

                WEArr.map(WorkoutExercise => {
                    var { DataType, MaxType, Notes } = WorkoutExercise;
                    DataType = DataType === 'static' ? 'static' : MaxType === 'Custom' ? 'dynamic-custom' : DataType;
                    MaxType = (DataType === 'static' || MaxType === 'Custom') ? 'Progress' : MaxType;

                    var ExerciseNotes = Notes.Coach;

                    // // Set DataType to be proper one for ExerciseType
                    //     if (ExerciseType === 1 && DataType.indexOf('dynamic') > -1 && MaxType !== 'Custom') DataType = 'dynamic-reps';
                    //     else if (ExerciseType === 2 && DataType.indexOf('dynamic') > -1 && MaxType !== 'Custom') DataType = 'dynamic-seconds';
                    //     else if (ExerciseType === 3 && DataType.indexOf('dynamic') > -1 && MaxType !== 'Custom') DataType = 'dynamic-distance';
                    //     else if (ExerciseType === 4 && DataType.indexOf('dynamic') > -1 && MaxType !== 'Custom') DataType = 'dynamic-weight';
                    //     else if (ExerciseType === 5 && DataType.indexOf('dynamic') > -1 && MaxType !== 'Custom') DataType = 'dynamic-weight';

                    NewWorkoutExercises.push(({ ...WorkoutExercise, CircuitNumber: CircuitNumber || 0, DataType, MaxType, ExerciseNotes }));

                    return null;
                });
            }
        }

        Injuries = Injuries.join(',');
        RepRange = RepRange.join(',');
        Tags = Tags.join(',');
        TrainingFocus = TrainingFocus.join(',');

        this.props.InsertEditWorkoutTemplateDetail({ ActiveType, ActivityId, CardioLevel, CopiedTemplateId, CustomImage, CustomName, DataType, Duration, Injuries, IntensityId, Location, RepRange, StartTime, Tags, TemplateId, TemplateImage, TemplateName, TrainerNote, TrainingFocus, Units, WorkoutExercises: NewWorkoutExercises, WorkoutLaps: Laps, WorkoutType }).then(({ PreSignedUrls }) => {
            if (!this.props.TryingInsertEditWorkoutTemplateDetailError) {
                if (TemplateImage && PreSignedUrls.length > 0) {
                    var UploadFunction = this.props.UploadToPresignedUrl;
                    var Promises = PreSignedUrls.map((PreSignedUrl, index) => CreateUploadToPresignedUrlPromise({ ContentType: 'WorkoutTemplate', File: SelectedImage, PresignedFormData: PreSignedUrl, UploadFunction }));
    
                    Promise.all(Promises).then(() => {
                        this.setState({ Touched: false }, () => {
                            this.onClearAutoSave({});
                            history.push({ pathname: `/library-workouts`, search: `?tab=workout-templates` });
                        });
                    });   
                } else {
                    this.setState({ Touched: false }, () => {
                        this.onClearAutoSave({});
                        history.push({ pathname: `/library-workouts`, search: `?tab=workout-templates` });
                    });
                }
            }
        });
    }

    onSaveCircuits = WorkoutExercises => {
        this.setState({ ShowReorderExercises: false, WorkoutExercises });
    }

    onSelectActivityPicture = ({ ActivityPicture, ActivityPictureId }) => {
        this.setState({ ActivityPicture, ActivityPictureId });
    }

    onSelectAdditionalActivity = ({ ActivityId, ActivityName, ActivityPictures }) => {
        var ActivityPicture = this.state.ActivityPicture;
        var ActivityPictureId = this.state.ActivityPictureId;

        if (this.state.ActivityPictureId !== 'custom') {
            ActivityPicture = ActivityPictures[0];
            ActivityPictureId = ActivityId;
        }

        this.setState({ ActivityId, ActivityName, ActivityPicture, ActivityPictureId, ActivityPictures, DisplayingFilter: null });
    }

    onSelectCardioLevel = CardioLevel => {
        if (this.state.WorkoutLaps.CardioLevel !== CardioLevel) this.setState({ WorkoutLaps: { ...this.state.WorkoutLaps, CardioLevel } });
    }

    onSelectInjury = ({ InjuryId, InjuryIndex }) => {
        if (InjuryIndex === -1) this.setState({ Injuries: [ ...this.state.Injuries, +InjuryId ] });
        else this.setState({ Injuries: [ ...this.state.Injuries.slice(0, InjuryIndex), ...this.state.Injuries.slice(InjuryIndex + 1) ] });
    }

    onSelectIntensity = IntensityId => {
        this.setState({ IntensityId });
    }

    onSelectRepRange = ({ RepRangeId, RepRangeIndex }) => {
        if (RepRangeIndex === -1) this.setState({ RepRange: [ ...this.state.RepRange, +RepRangeId ] });
        else this.setState({ RepRange: [ ...this.state.RepRange.slice(0, RepRangeIndex), ...this.state.RepRange.slice(RepRangeIndex + 1) ] });
    }

    onSelectTag = ({ TagId, TagIndex }) => {
        if (TagIndex === -1) this.setState({ Tags: [ ...this.state.Tags, +TagId ] });
        else this.setState({ Tags: [ ...this.state.Tags.slice(0, TagIndex), ...this.state.Tags.slice(TagIndex + 1) ] });
    }

    onSelectTrainingFocus = ({ TrainingFocusId, TrainingFocusIndex }) => {
        if (TrainingFocusIndex === -1) this.setState({ TrainingFocus: [ ...this.state.TrainingFocus, +TrainingFocusId ] });
        else this.setState({ TrainingFocus: [ ...this.state.TrainingFocus.slice(0, TrainingFocusIndex), ...this.state.TrainingFocus.slice(TrainingFocusIndex + 1) ] });
    }

    onToggleDropdown = (DisplayingFilter, ToggleStatus) => {
        this.setState({ DisplayingFilter: ToggleStatus ? DisplayingFilter : null });
    }

    onToggleMaxSet = ({ CircuitWorkoutExerciseIndex, MaxSet, WorkoutExerciseIndex, WorkoutSetIndex }) => {
        MaxSet = !!!+MaxSet;

        var WorkoutExercises = [ ...this.state.WorkoutExercises ];

        WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets = WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets.map((WorkoutSet, SetIndex) => {
            if (SetIndex === WorkoutSetIndex) return { ...WorkoutSet, MaxSet };
            else return { ...WorkoutSet, MaxSet: 0 }
        });

        this.setState({ WorkoutExercises });
    }

    onToggleShowAddExerciseModal = ShowAddExerciselModal => {
        this.setState({ ShowAddExerciselModal });
    }

    onToggleShowCustomName = () => {
        this.setState({ ShowCustomName: !this.state.ShowCustomName });
    }

    onToggleShowDateTimes = () => {
        this.setState({ ShowDateTimes: !this.state.ShowDateTimes });
    }

    onToggleShowExerciseDetailModal = (ShowExerciseDetailModal, Exercise) => {
        this.setState({ ShowExerciseDetailModal, ShowExerciseDetailPreview: Exercise || {} });
    }

    onToggleShowLearnMoreModal = ShowLearnMoreModal => {
        this.setState({ ShowLearnMoreModal });
    }

    onToggleShowReorderExercises = ShowReorderExercises => {
        this.setState({ ShowReorderExercises });
    }

    onUpdateWorkoutLap = ({ Active, BreakTime, LapIndex, LapType, Rest, Speed }) => {
        if (LapType === 'Active') {
            Active = +Active;
            BreakTime = 0;
            Rest = +Rest;
            Speed = +Speed;
        } else if (LapType === 'Break') {
            Active = 0;
            BreakTime = +BreakTime;
            Rest = 0;
            Speed = 0;
        }

        var Laps = [ ...this.state.WorkoutLaps.Laps ];
        Laps[LapIndex] = { ...Laps[LapIndex], Active, BreakTime, Rest, Speed };

        this.setState({ WorkoutLaps: { ...this.state.WorkoutLaps, Laps } });
    }

    onUpdateWorkoutSet = ({ CircuitWorkoutExerciseIndex, Distance, MaxSet, Reps, Seconds, TotalBreak: Rest, Weight, WorkoutExerciseIndex, WorkoutSetIndex }) => {
        var WorkoutExercises = [ ...this.state.WorkoutExercises ];
        WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets[WorkoutSetIndex] = { ...WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets[WorkoutSetIndex], ...Distance && { Distance }, MaxSet, ...Reps && { Reps }, Rest, ...Seconds && { Seconds }, ...Weight && { Weight } };
        
        this.setState({ WorkoutExercises });
    }

    onUploadTemplateImage = event => {
        if (event.target.files.length) {
            var SelectedImage = event.target.files[0];
            var FileExtension = getFileExtension(SelectedImage.name);

            if (validateFile({ FileExtension, FileType: 'Images' })) {
                if (SelectedImage.size <= this.state.MaxImageSize) {
                    var TemplateImage = URL.createObjectURL(SelectedImage);

                    this.setState({ ActivityPicture: TemplateImage, ActivityPictureId: 'custom', ImageTooLarge: false, SelectedImage, TemplateImage, WrongImageType: false});
                } else {
                    document.getElementById('TemplateImage').value = null;
                    this.setState({ ImageTooLarge: true, WrongImageType: false });
                }
            } else this.setState({ ImageTooLarge: false, WrongImageType: true });
        }
    }

    renderAddExerciseModal = () => {
        var { ShowAddExerciselModal, ShowExerciseDetailModal } = this.state;

        if (ShowAddExerciselModal) {
            var ReturnDataPathname = '/library/workout-template';
            var { TemplateId } = this.props.match.params;

            if (+TemplateId) ReturnDataPathname += `/${TemplateId}`;
            return (
                <AddExercise
                    DisplayNone={ShowExerciseDetailModal}
                    ExcludeExercises={this.state.WorkoutExercises.map(({ WorkoutExercises }) => WorkoutExercises.map(({ ExerciseId }) => +ExerciseId))}
                    OnAddExercises={SelectedExercises => this.onAddExercises(SelectedExercises)}
                    OnHide={() => this.onToggleShowAddExerciseModal(false)}
                    OnToggleShowExerciseDetailModal={(ShowExerciseDetailModal, Exercise) => this.onToggleShowExerciseDetailModal(ShowExerciseDetailModal, Exercise)}
                    ReturnData={{ pathname: ReturnDataPathname, state: { ...this.state, IsReturnData: true } }}
                    Show={ShowAddExerciselModal}
                />
            );
        }
    }

    renderAdditionalActivities = () => {
        var { AdditionalActivities } = this.state;

        return (
            <AdditionalActivityList>
                {
                    AdditionalActivities.map(({ label, Pictures, value }) => {
                        var Selected = value === this.state.ActivityId;

                        return (
                            <AdditionalActivityItem key={value} onClick={() => this.onSelectAdditionalActivity({ ActivityId: value, ActivityName: label, ActivityPictures: Pictures })} Selected={Selected}>
                                <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text={label} TextAlign="left" />
                            </AdditionalActivityItem>
                        );
                    })
                }
            </AdditionalActivityList>
        );
    }

    renderAdditionalActivity = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { ActivityId, ActivityName, ActivityPicture, ActivityPictures, AdditionalActivities, DisplayingFilter, DropdownSize } = this.state;

        return (
            <>
                <StyledInputLabelMultiText
                    TextAlign="center"
                    Texts={[
                        { FontFamily: 'medium', FontSize: 'medium-2', Text: t('WorkoutPlan_ChooseActivity') },
                        { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' },
                    ]}
                />

                <Spacer Size="extra-small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('choose_activity_and_picture')} TextAlign="center" />

                <Spacer Size="extra-small" />

                <StyledDropdown
                    ContentRenderer={this.renderAdditionalActivities}
                    Disabled={false}
                    ForceClose={DisplayingFilter !== 'AdditionalActivities'}
                    GetSize={Height => this.onGetSize(Height)}
                    Height={DisplayingFilter === 'AdditionalActivities' && DropdownSize}
                    IsList={false}
                    IsMobile={Device === 'mobile_small' || Device === 'mobile'}
                    ItemId="AdditionalActivities"
                    NeedsToLoad={AdditionalActivities.length === 0}
                    OnToggleDropdown={ToggleStatus => this.onToggleDropdown('AdditionalActivities', ToggleStatus)}
                    SelectedItemIds={[]}
                    Title={ActivityName || t('WorkoutPlan_ChooseActivity')}
                    TitleFontColor={ActivityName ? 'blue-astronaut' : null}
                    TitleFontFamily="semibold"
                />

                <Spacer Size="extra-small" />

                {
                    ActivityPictures.length > 0 &&
                    <ActivityPicturesContainer>
                        {
                            ActivityPictures.map(Picture => {
                                var Selected = ActivityPicture === Picture;

                                return (
                                    <ActivityPictureContainer key={Picture} onClick={() => this.onSelectActivityPicture({ ActivityPicture: Picture, ActivityPictureId: ActivityId })} Selected={Selected}>
                                        <StyledActivityImage
                                            ContainerWidth="100%"
                                            ImageSrc={Picture}
                                            SelfLoading
                                        />
                                    </ActivityPictureContainer>
                                );
                            })
                        }
                    </ActivityPicturesContainer>
                }

                {
                    !!+ActivityId &&
                    <>
                        <Spacer Size="medium" />

                        <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('or_upload_your_own')} TextAlign="center" />

                        <Spacer Size="extra-small" />

                        {this.renderUploadPicture()}
                    </>
                }
            </>
        );
    }

    renderAdditionalActivityDuration = () => {
        var { t } = this.props;
        var { AdditionalActivityDuration } = this.state;

        return (
            <>
                <StyledInputLabelMultiText
                    TextAlign="center"
                    Texts={[
                        { FontFamily: 'medium', FontSize: 'medium-2', Text: t('WorkoutPlan_duration_on_minutes') },
                        { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' },
                    ]}
                />

                <Spacer Size="extra-small" />

                <StyledDuration
                    NoDecimal
                    NoLabel
                    NoMargin
                    OnChange={this.onChangeAdditionalActivityDuration}
                    Placeholder='0'
                    Size="medium"
                    Type="number"
                    Value={AdditionalActivityDuration}
                />
            </>
        );
    }

    renderAddTrainerNote = () => {
        var { t } = this.props;
        var { TrainerNote } = this.state;

        return (
            <TrainerNoteContainer>
                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('WorkoutPlan_Trainer_note_to_client')} TextAlign="center" />

                <Spacer Size="small" />

                <TrainerNoteTextArea
                    OnBlur={this.onChangeTrainerNote}
                    NumRows={4}
                    Placeholder={t('WorkoutPlan_Trainer_note_to_client_placeholder')}
                    Value={TrainerNote}
                />
            </TrainerNoteContainer>
        );
    }

    renderAutoSaveOption = () => {
        if (!localStorage.getItem('AutoSave_WorkoutTemplate')) return null;

        var { t } = this.props;

        return (
            <>
                <Spacer Size="extra-extra-small" />

                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('_content_auto_saved')} />

                <Spacer Size="extra-extra-small" />
                
                <AutoSaveButtonGroup
                    Buttons={[{ BackgroundColorHover: 'blue-astronaut', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onClearAutoSave({ ClearState: true }), Title: t('clear') }]}
                    ButtonWidth="fit-content"
                    NotTouching
                    OwnRows
                />
            </>
        );
    }

    renderCardioLevels = () => {
        var { t } = this.props;
        var { Device } = this.props;

        var CardioLevels = [{ CardioLevel: t('low') }, { CardioLevel: t('medium') }, { CardioLevel: t('high') }];

        var CardioLevelButton = { BackgroundColorHover: 'blue-astronaut', ColorHover: 'white', FontFamily: 'semibold'};
        var CardioLevelButtons = CardioLevels.map(({ CardioLevel }, CardioLevelIndex) => ({ ...CardioLevelButton, OnClick: () => this.onSelectCardioLevel(CardioLevelIndex + 1), Selected: (CardioLevelIndex + 1) === this.state.WorkoutLaps.CardioLevel, Title: CardioLevel }));

        return (
            <>
                <StyledInputLabelMultiText
                    TextAlign="center"
                    Texts={[
                        { FontFamily: 'medium', FontSize: 'medium-2', Text: t('WorkoutPlan_Intensity') },
                        { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' },
                    ]}
                />
                
                <Spacer Size="small" />

                <ButtonGroup
                    Buttons={CardioLevelButtons}
                    ButtonWidth={Device === 'mobile' || Device === 'mobile_small' ? null : 'fit-content'}
                    NotTouching
                    OwnRows={Device === 'mobile' || Device === 'mobile_small' ? true : false}
                />
            </>
        );
    }

    renderCustomName = () => {
        var { t } = this.props;
        var { CustomName, ShowCustomName } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-2" Text={t('custom_name')} TextAlign="center" />
                    
                <DateTimeUseAllDaysContainer>
                    <DateTimeUseAllDays onClick={this.onToggleShowCustomName} Selected={ShowCustomName} />
                    <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" OnClick={this.onToggleShowCustomName} Text={t('custom_name_additional_activity_info')} TextAlign="left" />
                </DateTimeUseAllDaysContainer>

                {
                    ShowCustomName &&
                    <>
                        <Spacer Size="small" />

                        <StyledDuration
                            NoDecimal
                            NoLabel
                            NoMargin
                            OnChange={this.onChangeCustomName}
                            Placeholder={t('custom_name')}
                            Size="medium"
                            Type="text"
                            Value={CustomName}
                        />
                    </>
                }
            </>
        );
    }

    renderDateTimes = () => {
        var { t } = this.props;
        var { HourType: StateHourType, ShowDateTimes, StartTime } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={t('activity_start_time')} />

                <DateTimeUseAllDaysContainer>
                    <DateTimeUseAllDays onClick={this.onToggleShowDateTimes} Selected={ShowDateTimes} />
                    <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" OnClick={this.onToggleShowDateTimes} Text={t('additional_activity_choosetimes')} TextAlign="left" />
                </DateTimeUseAllDaysContainer>

                {
                    !!+ShowDateTimes &&
                    <>
                        <Spacer Size="small" />

                        <HourTypesContainer>
                            <HourType onClick={() => this.onChangeHourType(12)} Selected={StateHourType === 12}>
                                <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={t('hour_type', { HourType: '12' })} />
                            </HourType>
                            <HourType onClick={() => this.onChangeHourType(24)} Selected={StateHourType === 24}>
                                <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={t('hour_type', { HourType: '24' })} />
                            </HourType>
                        </HourTypesContainer>

                        <Spacer Size="small" />

                        <DateTimesContainer>
                            <DateTimeContainer>
                                <TimePicker
                                    defaultValue={moment(StartTime)}
                                    minuteStep={5}
                                    onChange={DateTime => this.onChangeDateTime(DateTime)}
                                    showSecond={false}
                                    use12Hours={StateHourType === 12}
                                    value={moment(StartTime)}
                                />
                            </DateTimeContainer>
                        </DateTimesContainer>
                    </>
                }
            </>
        );
    }

    renderEndurance = () => {
        var { t } = this.props;
        var { ActiveType, CardioLevel, DataType } = this.state.WorkoutLaps;

        if (!ActiveType || !CardioLevel || !DataType) {
            return <PageText FontFamily="medium-italic" FontSize="medium-2" NoMargin Text={t('fill_above_to_see_rest')} TextAlign="center" />;
        }

        var { Device } = this.props;
        var { Laps } = this.state.WorkoutLaps;

        var Units = '';
        if (this.state.TemplateId) Units = this.props.WorkoutTemplate.TemplateDetails.Units;
        else Units = this.props.UserDetails.PreferredMeasuringSystem.toLowerCase();
        
        var New_ActiveUnits = Units === 'imperial' ? 'yards' : 'meters';

        var Buttons = [];

        return (
            <WorkoutLapsContainer className="WorkoutLapsContainer">
                {
                    Laps.length > 0 ?
                    Laps.map(({ Active, ActiveUnits, BreakTime, LapNumber, LapType, Rest, Speed, SpeedUnits, WorkoutLapId }, LapIndex) => {                            
                        Buttons = [{ FontFamily: 'semibold', Icon: Plus, IconPosition: 'left', OnClick: () => this.onAddLap(LapIndex), Title: t('add_endurance_lap') }]
                        if (LapType === 'Active' && ((Laps[LapIndex + 1] && Laps[LapIndex + 1].LapType === 'Active') || (!Laps[LapIndex + 1 ]))) Buttons.unshift({ FontFamily: 'semibold', Icon: Plus, IconPosition: 'left', OnClick: () => this.onAddBreak(LapIndex), Title: t('add_endurance_break') });

                        ActiveUnits = ActiveUnits || New_ActiveUnits;

                        return (
                            <React.Fragment key={LapIndex}>
                                {(LapIndex !== 0) && <Spacer Size="extra-small" />}

                                <LapContainer className="LapContainer">
                                    <LapHeaderContainer className="LapHeaderContainer">
                                        {this.renderLapHeader(LapIndex, LapNumber, LapType, WorkoutLapId)}
                                    </LapHeaderContainer>
                                    <LapDetailsContainer className="LapDetailsContainer">
                                        {this.renderLapDetails({ Active, ActiveUnits, BreakTime, LapIndex, LapType, Rest, Speed, SpeedUnits, WorkoutLapId })}
                                    </LapDetailsContainer>
                                    {
                                        Device === 'laptop' &&
                                        <LapFooterContainer className="LapFooterContainer">
                                            <DeleteSetIcon
                                                Alt={`Delete lap ${WorkoutLapId}`}
                                                ImageSrc={DeleteX}
                                                Loading={false}
                                                OnClick={() => this.onDeleteLap(LapIndex)}
                                            />
                                        </LapFooterContainer>
                                    }
                                </LapContainer>

                                {
                                    ((Laps[LapIndex + 1]) || (LapIndex === Laps.length - 1)) &&
                                    <>
                                        <Spacer Size="extra-small" />

                                        <StyledAddLap
                                            Buttons={Buttons}
                                            ButtonWidth={Device === 'mobile_small' ? '100%' : 'fit-content'}
                                            NotTouching
                                            OwnRows={Device === 'mobile_small'}
                                        />
                                    </>
                                }
                            </React.Fragment>
                        );
                    })
                :
                    <>
                        <PageText FontFamily="medium-italic" FontSize="medium-2" NoMargin Text={t('add_first_exercise')} TextAlign="center" />
                        
                        <Spacer Size="extra-small" />

                        <StyledAddLap
                            Buttons={[{ FontFamily: 'semibold', Icon: Plus, IconPosition: 'left', OnClick: () => this.onAddLap(0), Title: t('add_endurance_lap') }]}
                            ButtonWidth={Device === 'mobile_small' ? '100%' : 'fit-content'}
                            NotTouching
                            OwnRows={Device === 'mobile_small'}
                        />
                    </>
                }
            </WorkoutLapsContainer>
        );
    }

    renderEnduranceActiveType = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { WorkoutLaps: { ActiveType } } = this.state;

        var Buttons = [
            { BackgroundColorHover: 'blue-astronaut', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onChangeActiveType('Time'), Selected: ActiveType === 'Time', Title: t('Time') },
            { BackgroundColorHover: 'blue-astronaut', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onChangeActiveType('Distance'), Selected: ActiveType === 'Distance', Title: t('Distance') }
        ];

        return (
            <>
                <StyledInputLabelMultiText
                    TextAlign="center"
                    Texts={[
                        { FontFamily: 'medium', FontSize: 'medium-2', Text: t('WorkoutTemplate_Endurance_Time_or_Distance') },
                        { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' },
                    ]}
                />

                <Spacer Size="small" />

                <ButtonGroup
                    Buttons={Buttons}
                    ButtonWidth={Device === 'mobile' || Device === 'mobile_small' ? null : 'fit-content'}
                    NotTouching
                    OwnRows={Device === 'mobile' || Device === 'mobile_small' ? true : false}
                />
            </>
        );
    }

    renderEnduranceDataType = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { WorkoutLaps: { DataType } } = this.state;

        var Buttons = [
            { BackgroundColorHover: 'blue-astronaut', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onChangeEnduranceDataType('dynamic'), Selected: DataType === 'dynamic', Title: t('dynamic_str') },
            { BackgroundColorHover: 'blue-astronaut', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onChangeEnduranceDataType('static'), Selected: DataType === 'static', Title: t('static_str') }
        ];

        return (
            <>
                <StyledInputLabelMultiText
                    TextAlign="center"
                    Texts={[
                        { FontFamily: 'medium', FontSize: 'medium-2', Text: t('WorkoutTemplate_Endurance_Dynamic_or_Static') },
                        { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' },
                    ]}
                />

                <Spacer Size="small" />

                <ButtonGroup
                    Buttons={Buttons}
                    ButtonWidth={Device === 'mobile' || Device === 'mobile_small' ? null : 'fit-content'}
                    NotTouching
                    OwnRows={Device === 'mobile' || Device === 'mobile_small' ? true : false}
                />
            </>
        );
    }

    renderExerciseDetailModal = () => {
        var { ShowExerciseDetailModal, ShowExerciseDetailPreview } = this.state;

        if (ShowExerciseDetailModal && Object.entries(ShowExerciseDetailPreview).length > 0) {
            return (
                <ExerciseDetail
                    ExerciseDetail_Preview={ShowExerciseDetailPreview}
                    OnHide={() => this.onToggleShowExerciseDetailModal(false)}
                    Show={ShowExerciseDetailModal}
                />
            );
        }
    }

    renderIntensities = () => {
        var { t } = this.props;
        var { Device, Intensities } = this.props;
        var Buttons = Intensities.map(({ IntensityId, IntensityName }) => ({ BackgroundColor: 'white', BackgroundColorHover: 'blue-astronaut', ColorHover: 'white', OnClick: () => this.onSelectIntensity(IntensityId), Selected: this.state.IntensityId === IntensityId, Title: IntensityName }));

        return (
            <>
                <StyledInputLabelMultiText
                    TextAlign="center"
                    Texts={[
                        { FontFamily: 'medium', FontSize: 'medium-2', Text: t('WorkoutPlan_Intensity') },
                        { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' },
                    ]}
                />

                <Spacer Size="extra-small" />

                <StyledIntensities
                    Buttons={Buttons}
                    ContainerWidth={(Device === 'mobile_small' || Device === 'mobile') ? '100%' : null}
                    NotTouching
                    OwnRows={Device === 'mobile_small' || Device === 'mobile'}
                />
            </>
        );
    }

    renderLapDetails = ({ Active, ActiveUnits, BreakTime, LapIndex, LapType, Rest, Speed, SpeedUnits, WorkoutLapId }) => {
        var { ActiveType, DataType } = this.state.WorkoutLaps;

        if (DataType === 'dynamic') SpeedUnits = '%';

        if (LapType === 'Active') {
            return (
                <ActiveLap
                    Active={Active}
                    ActiveUnits={ActiveUnits}
                    ActiveType={ActiveType}
                    DataType={DataType}
                    OnUpdateWorkoutLap={({ Active, Rest, Speed }) => this.onUpdateWorkoutLap({ Active, LapIndex, LapType, Rest, Speed })}
                    Rest={Rest}
                    SetType="Template"
                    Speed={Speed}
                    SpeedUnits={SpeedUnits}
                    WorkoutLapId={WorkoutLapId}
                />
            )
        } else if (LapType === 'Break') {
            return (
                <BreakLap
                    BreakTime={BreakTime}
                    OnUpdateWorkoutLap={({ BreakTime }) => this.onUpdateWorkoutLap({ BreakTime, LapIndex, LapType })}
                    WorkoutLapId={WorkoutLapId}
                />
            )
        }
    }

    renderLapHeader = (LapIndex, LapNumber, LapType, WorkoutLapId) => {
        var { t } = this.props;
        var { Device } = this.props;

        return (
            <LapHeaderContainer className="LapHeaderContainer">
                <LapHeaderContainerLeft>
                    {LapType === 'Active' && <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="center" NoMargin Text={`${t('lap_str_all_capital').toUpperCase()} ${LapNumber}`} TextAlign="right" />}
                    {LapType === 'Break' && <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('break').toUpperCase()} TextAlign="right" />}
                </LapHeaderContainerLeft>

                {
                    Device !== 'laptop' &&
                    <LapHeaderContainerRight>
                        <DeleteSetIcon
                            Alt={`Delete lap ${WorkoutLapId}`}
                            ImageSrc={DeleteX}
                            Loading={false}
                            OnClick={() => this.onDeleteLap(LapIndex)}
                        />
                    </LapHeaderContainerRight>
                }
            </LapHeaderContainer>
        );
    }

    renderLearnMoreModal = () => {
        var { ShowLearnMoreModal } = this.state;

        if (ShowLearnMoreModal) return <WorkoutTemplateFillIn OnHide={() => this.onToggleShowLearnMoreModal(false)} Show={ShowLearnMoreModal} />
    }

    renderLocation = () => {
        var { t } = this.props;
        var { Location } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-2" Text={t('location')} TextAlign="center" />
                    
                <StyledDuration
                    NoLabel
                    NoMargin
                    OnChange={this.onChangeLocation}
                    Placeholder={t('location_placeholder')}
                    Size="medium"
                    Type="text"
                    Value={Location}
                />
            </>
        );
    }

    renderReorderExercises = () => {
        var { WorkoutExercises } = this.state;

        return <WorkoutCircuits Circuits={WorkoutExercises} OnCancelCircuits={this.onCancelCircuits} OnSaveCircuits={this.onSaveCircuits} />;
    }

    renderUploadPicture = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { ActivityPictureId, ImageTooLarge, TemplateImage, WrongImageType } = this.state;

        var Buttons = [{ BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', FontFamily: 'semibold', Icon: Camera, IconPosition: 'left', IsInput: true, InputProps: { accept: "image/jpg,image/png,image/jpeg,.jpg,.png,.jpeg", id: "TemplateImage", type: "file", onChange: this.onUploadTemplateImage }, Title: t('Upload') }];

        return (
            <UploadContainer>
                <UploadButtonsRow>
                    <UploadButtonsContainer
                        Buttons={Buttons}
                        ButtonWidth="fit-content"
                        ContainerWidth={(Device === 'mobile_small' || Device === 'mobile') ? '100%' : null}
                        NotTouching
                        OwnRows={Device === 'mobile_small' || Device === 'mobile'}
                    />
                </UploadButtonsRow>

                {
                    TemplateImage &&
                    <ActivityPicturesContainer>
                        <ActivityPictureContainer onClick={() => this.onSelectActivityPicture({ ActivityPicture: TemplateImage, ActivityPictureId: 'custom' })} Selected={ActivityPictureId === 'custom'}>
                            <StyledActivityImage
                                ContainerWidth="100%"
                                ImageSrc={TemplateImage}
                                SelfLoading
                            />
                        </ActivityPictureContainer>
                    </ActivityPicturesContainer>
                }
                                
                {
                    ImageTooLarge || WrongImageType ?
                    <>
                        <Spacer Size="small" />

                        <PageText
                            FontColor="red-bittersweet"
                            FontFamily="semibold-italic"
                            FontSize="medium-1"
                            Text={t(ImageTooLarge ? 'media_wrongsize' : 'media_wrongtype', { AllowedTypes: '.jpg, .png, .jpeg', MaxSize: '10MB', Type: 'Image' })}
                            TextAlign="center"
                        />
                    </>
                :
                    null
                }
            </UploadContainer>
        );
    }

    SearchExercises = QuickSearchExerciseName => {
        console.log('Search for this: ', QuickSearchExerciseName);
    }

    onChangeQuickSearchExerciseName = event => {
        if (this._TypingTimeout) clearTimeout(this._TypingTimeout);
        
        this._TypingTimeout = setTimeout(() => this.SearchExercises(this.state.QuickSearchExerciseName), 5000);
    
        this.setState({ QuickSearchExerciseName: event.target.value });
    }

    renderCircuitSeparator = ({ CircuitType }) => {
        var { t } = this.props;

        return (
            <CircuitSeparator BackgroundColor={CircuitType === 'Circuit' ? 'turquoise-dark' : 'blue-royal'}>
                <PageText FontColor="white" FontFamily="bold" FontSize="medium-2" JustifyContent="center" NoMargin Text={t(`Workout_Set_Type_${CircuitType}`)} TextAlign="center" />
            </CircuitSeparator>
        );
    }

    renderInjuries = () => {
        var { t } = this.props;
        var { WorkoutTemplate: { Injuries } } = this.props;
        var { Injuries: StateInjuries } = this.state;

        return (
            <>
                <StyledMultiText
                    TextAlign="left"
                    Texts={[{ FontColor: 'blue-astronaut', FontFamily: 'semibold', FontSize: 'medium-3', Text: `${t('custom_template_select_blank_used', { Item: t('WorkoutPlan_injuries').toLowerCase()})}` }]}
                />

                <Spacer Size="extra-small" />

                <JointEquipmentContainer className="JointEquipmentContainer" Type="Injuries">
                    {
                        Injuries.map(({ label: InjuryName, value: InjuryId }) => {
                            var SelectedIndex = StateInjuries.indexOf(+InjuryId);

                            return (
                                <JointEquipmentItem className="JointEquipmentItem" key={InjuryId}>
                                    <PseudoCheckbox className="PseudoCheckbox" BackgroundColor="1A97FF" onClick={() => this.onSelectInjury({ InjuryId, InjuryIndex: SelectedIndex })} Selected={SelectedIndex !== -1} />

                                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={() => this.onSelectInjury({ InjuryId, InjuryIndex: SelectedIndex })} Text={InjuryName} TextAlign="left" />
                                </JointEquipmentItem>
                            );
                        })
                    }
                </JointEquipmentContainer>
            </>
        );
    }

    renderRepRange = () => {
        var { t } = this.props;
        var { WorkoutTemplate: { RepRange } } = this.props;
        var { RepRange: StateRepRange } = this.state;

        return (
            <>
                <StyledMultiText
                    TextAlign="left"
                    Texts={[{ FontColor: 'blue-astronaut', FontFamily: 'semibold', FontSize: 'medium-3', Text: `${t('custom_template_select_blank_used', { Item: t('rep_range').toLowerCase()})}` }]}
                />

                <Spacer Size="extra-small" />

                <JointEquipmentContainer className="JointEquipmentContainer" Type="RepRange">
                    {
                        RepRange.map(({ label: RepRangeName, value: RepRangeId }) => {
                            var SelectedIndex = StateRepRange.indexOf(+RepRangeId);

                            return (
                                <JointEquipmentItem className="JointEquipmentItem" key={RepRangeId}>
                                    <PseudoCheckbox className="PseudoCheckbox" BackgroundColor="1A97FF" onClick={() => this.onSelectRepRange({ RepRangeId, RepRangeIndex: SelectedIndex })} Selected={SelectedIndex !== -1} />

                                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={() => this.onSelectRepRange({ RepRangeId, RepRangeIndex: SelectedIndex })} Text={RepRangeName} TextAlign="left" />
                                </JointEquipmentItem>
                            );
                        })
                    }
                </JointEquipmentContainer>
            </>
        );
    }

    renderStrength = () => {
        if (this.state.ShowReorderExercises) return this.renderReorderExercises();

        var { t } = this.props;
        var { Device } = this.props;
        var { WorkoutExercises } = this.state;

        return (
            <>
                <WorkoutExercisesContainer className="WorkoutExercisesContainer">
                    {
                        WorkoutExercises.length > 0 ?
                        WorkoutExercises.map((WorkoutExercise, WorkoutExerciseIndex) => {
                            var CircuitType = WorkoutExercise.NumExercises >= 3 ? 'Circuit' : WorkoutExercise.NumExercises === 2 ? 'Superset' : 'WorkoutExercise';

                            return (
                                <React.Fragment key={WorkoutExercise.WorkoutExercises[0].ExerciseId}>
                                    {WorkoutExerciseIndex !== 0 && <Spacer Size="medium" />}

                                    <CircuitContainer CircuitType={CircuitType}>
                                        {
                                            WorkoutExercise.WorkoutExercises.map((CircuitWorkoutExercise, CircuitWorkoutExerciseIndex) => { 
                                                // if (+CircuitWorkoutExercise.ExerciseId !== 1) return null;

                                                return (
                                                    <React.Fragment key={CircuitWorkoutExercise.ExerciseId}>
                                                        {CircuitWorkoutExerciseIndex !== 0 && this.renderCircuitSeparator({ CircuitType })}
                    
                                                        <TemplateExerciseComponent
                                                            CircuitType={CircuitType}
                                                            Exercise={CircuitWorkoutExercise}
                                                            OnAddSet={() => this.onAddSet({ CircuitWorkoutExerciseIndex, WorkoutExerciseIndex })}
                                                            OnChangeDataType={DataType => this.onChangeDataType({ CircuitWorkoutExerciseIndex, DataType, WorkoutExerciseIndex })}
                                                            OnChangeExerciseNote={Note => this.onChangeExerciseNote({ CircuitWorkoutExerciseIndex, Note, WorkoutExerciseIndex })}
                                                            OnChangeMaxType={MaxType => this.onChangeMaxType({ CircuitWorkoutExerciseIndex, MaxType, WorkoutExerciseIndex })}
                                                            OnDeleteExercise={() => this.onDeleteExercise({ CircuitWorkoutExerciseIndex, WorkoutExerciseIndex })}
                                                            OnDeleteSet={WorkoutSetIndex => this.onDeleteSet({ CircuitWorkoutExerciseIndex, WorkoutExerciseIndex, WorkoutSetIndex })}
                                                            OnToggleMaxSet={(MaxSet, WorkoutSetIndex) => this.onToggleMaxSet({ CircuitWorkoutExerciseIndex, MaxSet, WorkoutExerciseIndex, WorkoutSetIndex })}
                                                            OnToggleShowExerciseDetailModal={(ShowExerciseDetailModal, Exercise) => this.onToggleShowExerciseDetailModal(ShowExerciseDetailModal, Exercise)}
                                                            OnToggleShowLearnMoreModal={ShowLearnMoreModal => this.onToggleShowLearnMoreModal(ShowLearnMoreModal)}
                                                            OnUpdateWorkoutSet={({ Distance, MaxSet, Reps, Seconds, TotalBreak, Weight, WorkoutSetIndex }) => this.onUpdateWorkoutSet({ CircuitWorkoutExerciseIndex, Distance, MaxSet, Reps, Seconds, TotalBreak, Weight, WorkoutExerciseIndex, WorkoutSetIndex })}
                                                        />
                                                    </React.Fragment>
                                                );
                                            })
                                        }    
                                    </CircuitContainer>
                                </React.Fragment>
                            );
                        })
                    :
                        <PageText FontFamily="medium-italic" FontSize="medium-2" NoMargin Text={t('add_first_exercise')} TextAlign="center" />
                    }
                </WorkoutExercisesContainer>

                <Spacer Size="medium" />

                <ButtonGroup
                    Buttons={[{ BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', FontFamily: 'semibold', Icon: Plus, IconPosition: 'left', OnClick: () => this.onToggleShowAddExerciseModal(true), Title: t('WorkoutPlan_add_new_exercise') }]}
                    ButtonWidth="100%"
                />

                {
                    WorkoutExercises.length > 0 &&
                    <>
                        <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                        <ButtonGroup
                            Buttons={[{ BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', FontFamily: 'semibold', OnClick: () => this.onToggleShowReorderExercises(true), Title: t('WorkoutPlan_reorder_exercises') }]}
                            ButtonWidth={Device === 'laptop' || Device === 'ipad' ? 'fit-content' : '100%'}
                            NotTouching
                        />
                    </>
                }
            </>
        );
    }

    renderTags = () => {
        var { t } = this.props;
        var { WorkoutTemplate: { Tags } } = this.props;
        var { Tags: StateTags } = this.state;

        return (
            <>
                <StyledMultiText
                    TextAlign="left"
                    Texts={[{ FontColor: 'blue-astronaut', FontFamily: 'semibold', FontSize: 'medium-3', Text: `${t('custom_template_select_blank_used', { Item: t('_tags').toLowerCase()})}` }]}
                />

                <Spacer Size="extra-small" />

                <JointEquipmentContainer className="JointEquipmentContainer" Type="Tags">
                    {
                        Tags.map(({ label: TagName, value: TagId }) => {
                            var SelectedIndex = StateTags.indexOf(+TagId);

                            return (
                                <JointEquipmentItem className="JointEquipmentItem" key={TagId}>
                                    <PseudoCheckbox className="PseudoCheckbox" BackgroundColor="1A97FF" onClick={() => this.onSelectTag({ TagId, TagIndex: SelectedIndex })} Selected={SelectedIndex !== -1} />

                                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={() => this.onSelectTag({ TagId, TagIndex: SelectedIndex })} Text={TagName} TextAlign="left" />
                                </JointEquipmentItem>
                            );
                        })
                    }
                </JointEquipmentContainer>
            </>
        );
    }

    renderTemplateName = () => {
        var { t } = this.props;
        var { TemplateName } = this.state;

        return (
            <>
                <StyledInputLabelMultiText
                    TextAlign="center"
                    Texts={[
                        { FontFamily: 'medium', FontSize: 'medium-2', Text: t('WorkoutPlan_name_your_template') },
                        { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' },
                    ]}
                />

                <Spacer Size="small" />

                <StyledTemplateName
                    NoLabel
                    NoMargin
                    OnChange={this.onChangeTemplateName}
                    Placeholder={t('WorkoutPlan_name_your_template')}
                    Size="medium"
                    Type="text"
                    Value={TemplateName}
                />

                <Spacer Size="extra-extra-small" />

                <PageText FontFamily="medium-italic" FontSize="medium-1" Text={t('this_is_also_name_of_workout')} TextAlign="center" />
            </>
        );
    }

    renderTrainingFocus = () => {
        var { t } = this.props;
        var { WorkoutTemplate: { TrainingFocus } } = this.props;
        var { TrainingFocus: StateTrainingFocus } = this.state;

        return (
            <>
                <StyledMultiText
                    TextAlign="left"
                    Texts={[{ FontColor: 'blue-astronaut', FontFamily: 'semibold', FontSize: 'medium-3', Text: `${t('custom_template_select_blank_used', { Item: t('_trainingfocus').toLowerCase()})}` }]}
                />

                <Spacer Size="extra-small" />

                <JointEquipmentContainer className="JointEquipmentContainer" Type="TrainingFocus">
                    {
                        TrainingFocus.map(({ label: TrainingFocusName, value: TrainingFocusId }) => {
                            var SelectedIndex = StateTrainingFocus.indexOf(+TrainingFocusId);

                            return (
                                <JointEquipmentItem className="JointEquipmentItem" key={TrainingFocusId}>
                                    <PseudoCheckbox className="PseudoCheckbox" BackgroundColor="1A97FF" onClick={() => this.onSelectTrainingFocus({ TrainingFocusId, TrainingFocusIndex: SelectedIndex })} Selected={SelectedIndex !== -1} />

                                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={() => this.onSelectTrainingFocus({ TrainingFocusId, TrainingFocusIndex: SelectedIndex })} Text={TrainingFocusName} TextAlign="left" />
                                </JointEquipmentItem>
                            );
                        })
                    }
                </JointEquipmentContainer>
            </>
        );
    }

    renderWorkoutDuration = () => {
        var { t } = this.props;
        var { Duration } = this.state;

        return (
            <>
                <StyledInputLabelMultiText
                    TextAlign="center"
                    Texts={[
                        { FontFamily: 'medium', FontSize: 'medium-2', Text: t('WorkoutPlan_duration_on_minutes') },
                        { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' },
                    ]}
                />

                <Spacer Size="small" />
                
                <StyledDuration
                    NoDecimal
                    NoLabel
                    NoMargin
                    OnChange={this.onChangeDuration}
                    Placeholder='0'
                    Size="medium"
                    Type="number"
                    Value={Duration}
                />
            </>
        );
    }

    renderWorkoutType = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { TemplateId, WorkoutType } = this.state;

        var Buttons = [];
        if (!TemplateId) {
            Buttons = [
                { BackgroundColorHover: 'blue-astronaut', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onChangeWorkoutType(1), Selected: WorkoutType === 1, Title: t('Strength') },
                { BackgroundColorHover: 'blue-astronaut', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onChangeWorkoutType(2), Selected: WorkoutType === 2, Title: t('Endurance') },
                { BackgroundColorHover: 'blue-astronaut', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onChangeWorkoutType(3), Selected: WorkoutType === 3, Title: t('additional_activity') }
            ]
        } else Buttons = [{ BackgroundColorHover: 'blue-astronaut', ColorHover: 'white', FontFamily: 'semibold', Selected: true, Title: t(WorkoutType === 1 ? 'Strength' : WorkoutType === 2 ? 'Endurance' : 'additional_activity') }]

        return (
            <>
                <StyledInputLabelMultiText
                    TextAlign="center"
                    Texts={[
                        { FontFamily: 'medium', FontSize: 'medium-2', Text: t('what_type_of_workout_session') },
                        { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' },
                    ]}
                />

                <Spacer Size="small" />

                <ButtonGroup
                    Buttons={Buttons}
                    ButtonWidth={Device === 'mobile' || Device === 'mobile_small' ? null : 'fit-content'}
                    NotTouching
                    OwnRows={Device === 'mobile' || Device === 'mobile_small' ? true : false}
                />
            </>
        );
    }

    render() {
        if (this.state.Loading || this.props.TryingGetWorkoutDetail) return <Loading />;

        var { t } = this.props;
        var { Device, TryingGetAdditionalActivityDetails, TryingGetWorkoutTemplateDetail, TryingInsertEditWorkoutTemplateDetail, UploadProgress } = this.props;
        var { ActivityId, ActivityPicture, ActivityPictureId, AdditionalActivityDuration, Duration, IntensityId, TemplateId, TemplateImage, TemplateName, WorkoutExercises, WorkoutLaps: { ActiveType, CardioLevel, DataType, Laps }, WorkoutType } = this.state;

        var ActionText = TemplateId ? t('WorkoutPlan_Edit_workout_template') : t('WorkoutPlan_Create_workout_template');
        var Breadcrumbs = [{ Name: t('ptadmin_navigation_library'), LinkURL: 'library-workouts', Props: true, Search: '?tab=workout-templates' }, { Name: ActionText }];

        var Disabled = WorkoutType && ActivityId && ActivityPicture && ((ActivityPictureId === 'custom' && TemplateImage) || ActivityId === ActivityPictureId) && (
            (WorkoutType === 1 && Duration && TemplateName && WorkoutExercises.length) ||
            (WorkoutType === 2 && Duration && TemplateName && ActiveType && CardioLevel && DataType && Laps.length) ||
            (WorkoutType === 3 && +AdditionalActivityDuration && IntensityId)
        ) ? false : true;

        var SubmitButtonProps = {
            Disabled,
            OnClick: this.onInsertEditWorkoutTemplate,
            Title: ActionText
        }

        return (
            <>
                {(TryingGetAdditionalActivityDetails || TryingGetWorkoutTemplateDetail || TryingInsertEditWorkoutTemplateDetail) && <Loading />}

                <Page renderBreadcrumbs={Breadcrumbs} renderSubmitButton={SubmitButtonProps}>
                    <PageText FontFamily="medium" FontSize="large" NoMargin Text={ActionText} />

                    {this.renderAutoSaveOption()}

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderWorkoutType()}

                    {
                        (WorkoutType === 1 || WorkoutType === 2) &&
                        <>
                            <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                            {this.renderTemplateName()}

                            <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                            {this.renderAdditionalActivity()}

                            <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                            {
                                WorkoutType === 2 &&
                                <>
                                    {this.renderEnduranceActiveType()}

                                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                                    {this.renderEnduranceDataType()}

                                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                                    {this.renderCardioLevels()}

                                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />
                                </>
                            }

                            {WorkoutType === 1 && this.renderStrength()}
                            {WorkoutType === 2 && this.renderEndurance()}

                            {
                                (WorkoutType !== 2 || (WorkoutType === 2 && ActiveType && CardioLevel && DataType)) &&
                                <>
                                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                                    {this.renderWorkoutDuration()}
                                </>
                            }
                        </>
                    }

                    {
                        WorkoutType === 3 &&
                        <>
                            <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                            {this.renderAdditionalActivity()}

                            <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />
                            
                            {this.renderIntensities()}

                            <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                            {this.renderAdditionalActivityDuration()}

                            <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                            {this.renderCustomName()}
                        </>
                    }

                    {
                        !!+WorkoutType && (WorkoutType !== 2 || (WorkoutType === 2 && ActiveType && CardioLevel && DataType)) &&
                        <>
                            <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                            <OptionalLine>
                                <PageText ElementType="span" FontFamily="semibold" FontSize="medium-2" NoMargin Text={t('optional')} TextAlign="center" />
                            </OptionalLine>

                            <Spacer Size="extra-extra-small" />

                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" Text={t('workout_template_optional_subtitle')} TextAlign="center" />

                            <Spacer Size="medium" />

                            {this.renderLocation()}

                            <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                            {this.renderDateTimes()}

                            <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                            {this.renderAddTrainerNote()}

                            <Spacer Size="medium" />

                            {this.renderInjuries()}

                            <Spacer Size="medium" />

                            {this.renderRepRange()}

                            <Spacer Size="medium" />

                            {this.renderTrainingFocus()}

                            <Spacer Size="medium" />

                            {this.renderTags()}
                        </>
                    }

                    {
                        UploadProgress.WorkoutTemplate && Object.entries(UploadProgress.WorkoutTemplate).length > 0 && 
                            <>
                                <Spacer Size="medium" />

                                <UploadProgressComponent UploadingItems={UploadProgress.WorkoutTemplate} />
                            </>
                    }
                </Page>

                {this.renderAddExerciseModal()}
                {this.renderLearnMoreModal()}
                {this.renderExerciseDetailModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,
        UserDetails: state.Auth.UserDetails,

        AdditionalActivities: state.AdditionalActivities.AdditionalActivities,
        Intensities: state.AdditionalActivities.Intensities,

        TryingGetAdditionalActivityDetails: state.AdditionalActivities.TryingGetAdditionalActivityDetails,
        TryingGetAdditionalActivityDetailsError: state.AdditionalActivities.TryingGetAdditionalActivityDetailsError,

        RetrievedAdditionalActivitySettings: state.Templates.RetrievedAdditionalActivitySettings,
        WorkoutTemplate: state.Templates.WorkoutTemplate,

        UploadProgress: state.UploadContent.UploadProgress,

        TryingGetWorkoutTemplateDetail: state.Templates.TryingGetWorkoutTemplateDetail,
        TryingGetWorkoutTemplateDetailError: state.Templates.TryingGetWorkoutTemplateDetailError,
        TryingInsertEditWorkoutTemplateDetail: state.Templates.TryingInsertEditWorkoutTemplateDetail,
        TryingInsertEditWorkoutTemplateDetailError: state.Templates.TryingInsertEditWorkoutTemplateDetailError,

        WorkoutToTemplateDetails: state.SaveData.WorkoutToTemplateDetails
    };
};

export default withTranslation()(connect(mapStateToProps, { ClearAdditionalActivityDetails, ClearSaveData, ClearWorkoutTemplateDetail, GetAdditionalActivityDetails, GetWorkoutTemplateDetail, InsertEditWorkoutTemplateDetail, SaveDataInProps, UploadToPresignedUrl } )(WorkoutTemplate));