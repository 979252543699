import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { DownloadContractInvoicePDF, ViewOrganizationMemberContractInvoices, ViewOrganizationMemberContractInvoicesFilters } from '../../Services/Actions';
import moment from 'moment';

import * as styles from './styles';
import * as IStyles from './Invoice_styles';

import Filters from './Invoices_Filters';
import Header from './Header';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Loading from '../../Components/Loading';
import MultiText from '../../Components/Text/MultiText';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import Info from '../../Modals/Info';

import { PageRange } from '../../Functions';

import DownArrow from '../../Assets/Icons/DownArrow.png';
import DownloadIcon from '../../Assets/Icons/Download_Gray.png';
import { ReactComponent as FilterSVG } from '../../Assets/SVG/Filter.svg';
import UpArrow from '../../Assets/Icons/UpArrow.png';

class InvoiceComponent extends React.Component {
    onDownloadInvoice = () => {
        this.props.OnDownloadInvoice(this.props.Invoice.InvoiceId);
    }

    renderPaidStatus = ({ DaysLate, IsLate, PaymentMadeDate }) => {
        var { t } = this.props;

         // Paid
            if (!!PaymentMadeDate) return <IStyles.StyledEllipsisText FontColor="green-success" FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin NoWrap Text={`${t('contracts_invoice_paid')}: ${moment(PaymentMadeDate).format('[NumbersDate]')}`} TextAlign="left" />
        // Pending
            else {
                if (IsLate) return <IStyles.StyledEllipsisText FontColor="red-bittersweet" FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin NoWrap Text={`${t('contracts_invoice_not_paid')}: ${t('contracts_invoice_days_late', { DaysLate: Math.abs(DaysLate) })}`} TextAlign="left" />

                return <IStyles.StyledEllipsisText FontColor="black" FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin NoWrap Text={t('contracts_invoice_not_paid_yet')} TextAlign="left" />
            }
    }

    render() {
        var { t } = this.props;
        var { Device } = this.props;
        var { BranchName, Client, InvoiceSentDate, RegionName, PaymentAmount, PaymentDueDate, PaymentMadeDate, Trainer } = this.props.Invoice;

        var DaysLate = PaymentMadeDate ? moment(PaymentDueDate).diff(moment(PaymentMadeDate), 'days') : moment(PaymentDueDate).diff(moment().utc().format('YYYY-MM-DD'), 'days');
        var IsLate = DaysLate >= 0 ? 0 : 1;

        if (Device !== 'laptop') {
            return (
                <IStyles.InvoiceContainerMobile className="InvoiceContainerMobile">
                    {/* Download Icon */}
                        <IStyles.InfoContainerMobile className="InfoContainerMobile">
                            <ButtonGroup
                                Buttons={[{ BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', FontFamily: 'semibold', IconOnly: true, Icon: DownloadIcon, IconPosition: 'left', IconSize: { Height: '20px', Width: '20px' }, OnClick: this.onDownloadInvoice, Title: t('download.pdf') }]}
                                ButtonWidth="fit-content"
                                NotTouching
                            />
                            <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('download.pdf')} />
                        </IStyles.InfoContainerMobile>

                    {/* Coach Name */}
                        <IStyles.InfoContainerMobile className="InfoContainerMobile">
                            <MultiText
                                Texts={[
                                    { FontFamily: 'semibold', FontSize: 'small', Text: `${t('coach')}:` },
                                    { FontFamily: 'medium', FontSize: 'small', Text: Trainer }
                                ]}
                            />
                        </IStyles.InfoContainerMobile>

                    {/* Client Name */}
                        <IStyles.InfoContainerMobile className="InfoContainerMobile">
                            <MultiText
                                Texts={[
                                    { FontFamily: 'semibold', FontSize: 'small', Text: `${t('client')}:` },
                                    { FontFamily: 'medium', FontSize: 'small', Text: Client }
                                ]}
                            />
                        </IStyles.InfoContainerMobile>

                    {/* Branch Name */}
                        <IStyles.InfoContainerMobile className="InfoContainerMobile">
                            <MultiText
                                Texts={[
                                    { FontFamily: 'semibold', FontSize: 'small', Text: `${t('_branch')}:` },
                                    { FontFamily: 'medium', FontSize: 'small', Text: BranchName }
                                ]}
                            />
                        </IStyles.InfoContainerMobile>

                    {/* Region Name */}
                        <IStyles.InfoContainerMobile className="InfoContainerMobile">
                            <MultiText
                                Texts={[
                                    { FontFamily: 'semibold', FontSize: 'small', Text: `${t('_region')}:` },
                                    { FontFamily: 'medium', FontSize: 'small', Text: RegionName || t('_no_region') }
                                ]}
                            />
                        </IStyles.InfoContainerMobile>

                    {/* Sent Date */}
                        <IStyles.InfoContainerMobile className="InfoContainerMobile">
                            <MultiText
                                Texts={[
                                    { FontFamily: 'semibold', FontSize: 'small', Text: `${t('_sent_date')}:` },
                                    { FontFamily: 'medium', FontSize: 'small', Text: moment(InvoiceSentDate).utc().format('[NumbersDate]') }
                                ]}
                            />
                        </IStyles.InfoContainerMobile>

                    {/* Due Date */}
                        <IStyles.InfoContainerMobile className="InfoContainerMobile">
                            <MultiText
                                Texts={[
                                    { FontFamily: 'semibold', FontSize: 'small', Text: `${t('_due_date')}:` },
                                    { FontFamily: 'medium', FontSize: 'small', Text: moment(PaymentDueDate).utc().format('[NumbersDate]') }
                                ]}
                            />
                        </IStyles.InfoContainerMobile>

                    {/* Paid Status */}
                        <IStyles.InfoContainerMobile className="InfoContainerMobile">
                            {this.renderPaidStatus({ DaysLate, IsLate, PaymentMadeDate })}
                        </IStyles.InfoContainerMobile>

                    {/* Value */}
                        <IStyles.InfoContainerMobile className="InfoContainerMobile">
                            <MultiText
                                Texts={[
                                    { FontFamily: 'semibold', FontSize: 'small', Text: `${t('_value')}:` },
                                    { FontFamily: 'medium', FontSize: 'small', Text: PaymentAmount }
                                ]}
                            />
                        </IStyles.InfoContainerMobile>
                </IStyles.InvoiceContainerMobile>
            );
        }
          
        return (
            <IStyles.InvoiceContainer className="InvoiceContainer">
                {/* Download Icon */}
                    <ButtonGroup
                        Buttons={[
                            { BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', FontFamily: 'semibold', IconOnly: true, Icon: DownloadIcon, IconPosition: 'left', IconSize: { Height: '20px', Width: '20px' }, OnClick: this.onDownloadInvoice }
                        ]}
                        NotTouching
                    />
                {/* Coach Name */}
                    <IStyles.InfoContainer className="InfoContainer">
                        <IStyles.StyledEllipsisText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={Trainer} />
                    </IStyles.InfoContainer>

                {/* Client Name */}
                    <IStyles.InfoContainer className="InfoContainer">
                        <IStyles.StyledEllipsisText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={Client} />
                    </IStyles.InfoContainer>

                {/* Branch Name */}
                    <IStyles.InfoContainer className="InfoContainer">
                        <IStyles.StyledEllipsisText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={BranchName} />
                    </IStyles.InfoContainer>

                {/* Region Name */}
                    <IStyles.InfoContainer className="InfoContainer">
                        <IStyles.StyledEllipsisText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={RegionName || t('_no_region')} />
                    </IStyles.InfoContainer>

                {/* Sent Date */}
                    <IStyles.InfoContainer className="InfoContainer">
                        <IStyles.StyledEllipsisText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={moment(InvoiceSentDate).utc().format('[NumbersDate]')} />
                    </IStyles.InfoContainer>

                {/* Due Date */}
                    <IStyles.InfoContainer className="InfoContainer">
                        <IStyles.StyledEllipsisText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={moment(PaymentDueDate).utc().format('[NumbersDate]')} />
                    </IStyles.InfoContainer>

                {/* Paid Status */}
                    <IStyles.InfoContainer className="InfoContainer">
                        {this.renderPaidStatus({ DaysLate, IsLate, PaymentMadeDate })}
                    </IStyles.InfoContainer>

                {/* Value */}
                    <IStyles.InfoContainer className="InfoContainer">
                        <IStyles.StyledEllipsisText FontFamily="medium" FontSize="small" JustifyContent="flex-end" NoMargin TextAlign="left" Text={`${PaymentAmount}`} />
                    </IStyles.InfoContainer>
            </IStyles.InvoiceContainer>
        )
    }
}

class OrganizationInvoices extends React.Component {
    state = {
        HasLoadedFilters: false,
        FirstLoad: false,
        FiltersUpdated: false,
        FilterOptions: {
            Coaches: [],
            Clients: [],
            Branches: [],
            Regions: []
        },
        Filters: {
            Coaches: [],
            Clients: [],
            Branches: [],
            Regions: [],
            DueDate: {
                StartDate: null,
                EndDate: null
            },
            SentDate: {
                StartDate: null,
                EndDate: null
            },
            PaidStatus: {
                Paid: false,
                Refunded: false,
                Unpaid_Late: false,
                Unpaid_OnTime: false
            }
        },
        Sorting: {
            SortCol: 'SentDate',
            SortDir: 'DESC'
        },
        Invoices: [],
        IsMoreResults: false,
        PageNo: 1,
        PageSize: 20,
        TotalRecords: 0,
        ShowFiltersModals: false
    };
    
    componentDidMount() {
        this.onLoadInvoices();
    }

    onDownloadInvoice = InvoiceId => {
        this.props.DownloadContractInvoicePDF({ InvoiceId });
    }

    onBlurFilter = () => {
        if (this.state.FiltersUpdated) {
            this.setState({ FiltersUpdated: false, PageNo: 1 }, () => {
                this.onLoadInvoices();
            })
        }
    }

    onChangeDate = ({ StartDate, EndDate, Type }) => {
        var Filters = { ...this.state.Filters };

        // Calendar dates were selected (otherwise they are null as calendar was cleared)
            if (StartDate !== null && EndDate !== null) {
                StartDate = StartDate.toISOString().slice(0, 10);
                EndDate = EndDate.toISOString().slice(0, 10);
            }

        Filters[Type] = { StartDate, EndDate };

        this.setState({ Filters, PageNo: 1 }, () => {
            this.onLoadInvoices();
        });
    }
    
    onChangeFilter = ({ Data, Type }) => {
        var Filters = { ...this.state.Filters };
        Filters[Type] = Data;

        // this.setState({ Filters, FiltersUpdated: true });

        this.setState({ Filters, PageNo: 1 }, () => {
            this.onLoadInvoices();
        })
    }

    onLoadFilters = () => {
        if (this.state.HasLoadedFilters) return null;

        this.props.ViewOrganizationMemberContractInvoicesFilters().then(({ Branches, Clients, Coaches, Regions }) => {
            var FilterOptions = { ...this.state.FilterOptions, Branches, Clients, Coaches, Regions };
            this.setState({ HasLoadedFilters: true, FilterOptions });
        })
    }

    onLoadInvoices = () => {
        var { Filters, PageNo, PageSize, Sorting } = this.state;
        var { Coaches, Clients, Branches, Regions, DueDate, SentDate, PaidStatus } = Filters;
        var { Paid, Refunded, Unpaid_Late, Unpaid_OnTime } = PaidStatus;

        PaidStatus = [];
        if (Paid) PaidStatus.push('Paid');
        if (Refunded) PaidStatus.push('Refunded');
        if (Unpaid_Late) PaidStatus.push('Unpaid_Late');
        if (Unpaid_OnTime) PaidStatus.push('Unpaid_OnTime');
        PaidStatus.join(',');

        var BranchIds = Branches.map(({ BranchId }) => BranchId).join(',');
        var ClientIds = Clients.map(({ UserId }) => UserId).join(',');
        var RegionIds = Regions.map(({ RegionId }) => RegionId).join(',');
        var TrainerIds = Coaches.map(({ UserId }) => UserId).join(',');
        var { EndDate: EndDate_DueDate, StartDate: StartDate_DueDate } = DueDate;
        var { EndDate: EndDate_SentDate, StartDate: StartDate_SentDate } = SentDate;
        var { SortCol, SortDir } = Sorting;

        this.props.ViewOrganizationMemberContractInvoices({ BranchIds, ClientIds, EndDate_DueDate, EndDate_SentDate, PageNo, PageSize, PaidStatus, RegionIds, SortCol, SortDir, StartDate_DueDate, StartDate_SentDate, TrainerIds }).then(({ Invoices, IsMoreResults, TotalRecords }) => {
            this.setState({ FirstLoad: true, Invoices, IsMoreResults, TotalRecords });
        })
    }

    onPaginate = ({ NewPageNo }) => {
        if (this.state.PageNo !== NewPageNo) this.setState({ PageNo: NewPageNo }, () => this.onLoadInvoices());
    }

    onSelectPaidStatus = PaidStatus => {
        var NewPaidStatus = { ...this.state.Filters.PaidStatus };
        NewPaidStatus[PaidStatus] = !NewPaidStatus[PaidStatus];

        var Filters = { ...this.state.Filters };
        Filters.PaidStatus = NewPaidStatus;

        this.setState({ Filters, PageNo: 1 }, () => {
            this.onLoadInvoices();
        });
    }

    onToggleShowFiltersModal = ShowFiltersModals => {
        this.setState({ ShowFiltersModals });
    }

    onToggleSorting = ({ SortCol: NewSortCol }) => {
        var { SortCol, SortDir } = this.state.Sorting;

        // SortDir Goes From ASC --> DESC --> Nothing

        // Toggle Sorting Of Already Sorted Column
            if (SortCol === NewSortCol) {
                if (SortDir === 'ASC') SortDir = 'DESC';
                else if (SortDir === 'DESC') {
                    SortDir = '';
                    SortCol = '';
                }
                else if (SortDir === '') SortDir = 'ASC';
            }
        // Start Sorting On New Column
            else {
                SortCol = NewSortCol;
                SortDir = 'ASC';
            }

        this.setState({ Sorting: { SortCol, SortDir } }, () => this.onLoadInvoices());
    }

    renderInvoices = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { FirstLoad, Invoices, Sorting: { SortCol, SortDir } } = this.state;

        if (!FirstLoad) return null;

        if (!Invoices.length) return <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_no_results')} />

        if (Device !== 'laptop') {
            return (
                <>
                    {this.renderPagination()}

                    <Spacer Size="small" />

                    <IStyles.InvoicesContainerMobile className="InvoicesContainerMobile">
                        {
                            Invoices.map(Invoice => {
                                return <InvoiceComponent key={Invoice.InvoiceId} Device={Device} Invoice={Invoice} OnDownloadInvoice={this.onDownloadInvoice} t={t} />
                            })
                        }
                    </IStyles.InvoicesContainerMobile>
                </>
            );
        }

        return (
            <>
                {this.renderPagination()}

                <Spacer Size="small" />

                <IStyles.InvoicesContainer className="InvoicesContainer">
                    <IStyles.InvoiceContainer className="InvoiceContainer" Header>
                        <div />
                        {/* Coach Name */}
                            <IStyles.InfoContainer className="InfoContainer">
                                <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" OnClick={() => this.onToggleSorting({ SortCol: 'CoachName' })} NoMargin TextAlign="left" Text={t('coach')} />
                                {SortCol === 'CoachName' && <IStyles.SortingIcon ImageSrc={SortDir === 'DESC' ? DownArrow : UpArrow} OnClick={() => this.onToggleSorting({ SortCol: 'CoachName' })} />}
                            </IStyles.InfoContainer>

                        {/* Client Name */}
                            <IStyles.InfoContainer className="InfoContainer">
                                <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin OnClick={() => this.onToggleSorting({ SortCol: 'ClientName' })} TextAlign="left" Text={t('client')} />
                                {SortCol === 'ClientName' && <IStyles.SortingIcon ImageSrc={SortDir === 'DESC' ? DownArrow : UpArrow} OnClick={() => this.onToggleSorting({ SortCol: 'ClientName' })} />}
                            </IStyles.InfoContainer>

                        {/* Branch Name */}
                            <IStyles.InfoContainer className="InfoContainer">
                                <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin OnClick={() => this.onToggleSorting({ SortCol: 'BranchName' })} TextAlign="left" Text={t('_branch')} />
                                {SortCol === 'BranchName' && <IStyles.SortingIcon ImageSrc={SortDir === 'DESC' ? DownArrow : UpArrow} OnClick={() => this.onToggleSorting({ SortCol: 'BranchName' })} />}
                            </IStyles.InfoContainer>

                        {/* Region Name */}
                            <IStyles.InfoContainer className="InfoContainer">
                                <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin OnClick={() => this.onToggleSorting({ SortCol: 'RegionName' })} TextAlign="left" Text={t('_region')} />
                                {SortCol === 'RegionName' && <IStyles.SortingIcon ImageSrc={SortDir === 'DESC' ? DownArrow : UpArrow} OnClick={() => this.onToggleSorting({ SortCol: 'RegionName' })} />}
                            </IStyles.InfoContainer>

                        {/* Sent Date */}
                            <IStyles.InfoContainer className="InfoContainer">
                                <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" OnClick={() => this.onToggleSorting({ SortCol: 'SentDate' })} NoMargin TextAlign="left" Text={t('_sent_date')} />
                                {SortCol === 'SentDate' && <IStyles.SortingIcon ImageSrc={SortDir === 'DESC' ? DownArrow : UpArrow} OnClick={() => this.onToggleSorting({ SortCol: 'SentDate' })} />}
                            </IStyles.InfoContainer>

                        {/* Due Date */}
                            <IStyles.InfoContainer className="InfoContainer">
                                <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" OnClick={() => this.onToggleSorting({ SortCol: 'DueDate' })} NoMargin TextAlign="left" Text={t('_due_date')} />
                                {SortCol === 'DueDate' && <IStyles.SortingIcon ImageSrc={SortDir === 'DESC' ? DownArrow : UpArrow} OnClick={() => this.onToggleSorting({ SortCol: 'DueDate' })} />}
                            </IStyles.InfoContainer>

                        {/* Paid Status */}
                            <IStyles.InfoContainer className="InfoContainer">
                                <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_paid_status')} />
                            </IStyles.InfoContainer>

                        {/* Value */}
                            <IStyles.InfoContainer className="InfoContainer">
                                <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-end" OnClick={() => this.onToggleSorting({ SortCol: 'Value' })} NoMargin TextAlign="left" Text={t('_value')} />
                                {SortCol === 'Value' && <IStyles.SortingIcon ImageSrc={SortDir === 'DESC' ? DownArrow : UpArrow} OnClick={() => this.onToggleSorting({ SortCol: 'Value' })} />}
                            </IStyles.InfoContainer>
                    </IStyles.InvoiceContainer>
                    {
                        Invoices.map(Invoice => {
                            return <InvoiceComponent key={Invoice.InvoiceId} Device={Device} Invoice={Invoice} OnDownloadInvoice={this.onDownloadInvoice} t={t} />
                        })
                    }
                </IStyles.InvoicesContainer>
            </>
        )
    }

    renderPagination = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { PageNo, PageSize, TotalRecords } = this.state;

        var CurrentPageNo = PageNo;
        var TotalPages = Math.ceil(TotalRecords / PageSize);

        var { EndPageNo, StartPageNo } = PageRange({ PageNo: CurrentPageNo, TotalPages });

        return (
            <IStyles.PaginationContainer className="PaginationContainer">
                <IStyles.LeftPagination className="LeftPagination" HasPrevLink={CurrentPageNo !== 1}>
                    {
                        TotalPages !== 1 &&
                        <>
                            {CurrentPageNo !== 1 && <PageText FontColorHover={'blue-abel'} FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={() => this.onPaginate({ NewPageNo: CurrentPageNo - 1 })} Text={t('prev')} TextAlign="left" />}

                            <IStyles.PageNumbersContainer className="PageNumbersContainer">
                                {
                                    Array((EndPageNo - StartPageNo) + 1).fill().map((item, index) => {
                                        var Selected = CurrentPageNo === StartPageNo + index;

                                        return (
                                            <IStyles.PageNumberContainer className="PageNumberContainer" key={index} onClick={() => this.onPaginate({ NewPageNo: StartPageNo + index })} Selected={Selected}>
                                                {`${StartPageNo + index}`}
                                            </IStyles.PageNumberContainer>
                                        );
                                    })
                                }
                            </IStyles.PageNumbersContainer>

                            {CurrentPageNo !== TotalPages && <PageText FontColorHover={'blue-abel'} FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-end" NoMargin OnClick={() => this.onPaginate({ NewPageNo: CurrentPageNo + 1 })} Text={t('next_with_first_char_uppercase')} TextAlign="right" />}
                        </>
                    }
                </IStyles.LeftPagination>
                
                {TotalPages !== 1 && Device === 'mobile_small' && <Spacer Size="extra-small" />}

                <IStyles.RightPagination className="RightPagination">
                    {this.renderViewCount()}
                </IStyles.RightPagination>
            </IStyles.PaginationContainer>
        );
    }

    renderBody = () => {
        var { t } = this.props;
        var { Device, TryingViewOrganizationMemberContractInvoicesFilters } = this.props;
        var { Coaches, Clients, Branches, Regions } = this.state.FilterOptions;
        var { DueDate, PaidStatus, SentDate } = this.state.Filters;

        return (
            <>
                {<Header PageTitle={this.props.PageTitle} />}

                {
                    Device === 'laptop' &&
                    <Filters
                        Branches={Branches}
                        Clients={Clients}
                        Coaches={Coaches}
                        Regions={Regions}
                        DueDate={DueDate}
                        PaidStatus={PaidStatus}
                        SentDate={SentDate}
                        Loading={TryingViewOrganizationMemberContractInvoicesFilters}
                        OnBlurFilter={this.onBlurFilter}
                        OnChangeFilter={this.onChangeFilter}
                        OnFocusFilter={this.onLoadFilters}
                        OnChangeDate={this.onChangeDate}
                        OnSelectPaidStatus={this.onSelectPaidStatus}
                    />
                }

                <styles.BodyContainer className="BodyContainer">
                    {this.renderOverallSummary()}

                    {
                        Device !== 'laptop' &&
                        <>
                            <Spacer Size="small" />

                            <ButtonGroup
                                Buttons={[
                                    { BackgroundColor: "white-concrete", BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', IconOnly: true, IconSVG: FilterSVG, IconSVGColor: 'black', IconPosition: 'left', OnClick: () => this.onToggleShowFiltersModal(true), Title: t('_select_filters') }
                                ]}
                                NotTouching
                            />
                        </>
                    }

                    <Spacer Size="small" />

                    {this.renderInvoices()}
                </styles.BodyContainer>
            </>
        );
    }

    renderFiltersModal_Info = () => {
        var { TryingViewOrganizationMemberContractInvoices, TryingViewOrganizationMemberContractInvoicesFilters } = this.props;
        var { Coaches, Clients, Branches, Regions } = this.state.FilterOptions;
        var { Branches: SelectedBranches, Clients: SelectedClients, Coaches: SelectedCoaches, Regions: SelectedRegions, DueDate, PaidStatus, SentDate } = this.state.Filters;
        
        return (
            <Filters
                Branches={Branches}
                Clients={Clients}
                Coaches={Coaches}
                Regions={Regions}
                DueDate={DueDate}
                PaidStatus={PaidStatus}
                SentDate={SentDate}
                SelectedContent={{
                    Branches: SelectedBranches,
                    Clients: SelectedClients,
                    Coaches: SelectedCoaches,
                    Regions: SelectedRegions
                }}
                IsModal
                Loading={TryingViewOrganizationMemberContractInvoices || TryingViewOrganizationMemberContractInvoicesFilters}
                OnBlurFilter={this.onBlurFilter}
                OnChangeFilter={this.onChangeFilter}
                OnFocusFilter={this.onLoadFilters}
                OnChangeDate={this.onChangeDate}
                OnSelectPaidStatus={this.onSelectPaidStatus}
            />
        );
    }

    renderFiltersModal = () => {
        var { ShowFiltersModals } = this.state;

        if (ShowFiltersModals) {
            return (
                <Info
                    BottomButton={null}
                    InformationRenderer={this.renderFiltersModal_Info}
                    OnHide={() => this.onToggleShowFiltersModal(false)}
                    Show={ShowFiltersModals}
                />
            );
        }
    }

    renderOverallSummary = () => {
        var { t } = this.props;

        return (
            <styles.OverallSummaryHeaderContainer className="OverallSummaryHeaderContainer">
                <PageText FontFamily="medium" FontSize="large" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_invoices')} />
            </styles.OverallSummaryHeaderContainer>
        );
    }

    renderViewCount = () => {
        var { t } = this.props;
        var { PageNo, PageSize, TotalRecords } = this.state;

        return (
            <IStyles.ViewCount className="ViewCount">
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('items_count_viewing')} />
                &nbsp;
                <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`${((PageNo - 1) * PageSize) + 1} - ${(PageNo * PageSize) < TotalRecords ? (PageNo * PageSize) : TotalRecords}`} />
                &nbsp;
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('of')} />
                &nbsp;
                <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`${TotalRecords}`} />
            </IStyles.ViewCount>
        )
    }

    render() {
        var { TryingDownloadContractPDF, TryingViewOrganizationMemberContractInvoices } = this.props;

        return (
            <>
                {(TryingDownloadContractPDF || TryingViewOrganizationMemberContractInvoices) && <Loading />}

                {this.renderBody()}

                {this.renderFiltersModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        UserDetails: state.Auth.UserDetails,

        TryingViewOrganizationMemberContractInvoices: state.Income.TryingViewOrganizationMemberContractInvoices,
        TryingViewOrganizationMemberContractInvoicesError: state.Income.TryingViewOrganizationMemberContractInvoicesError,
        TryingViewOrganizationMemberContractInvoicesFilters: state.Income.TryingViewOrganizationMemberContractInvoicesFilters,
        TryingViewOrganizationMemberContractInvoicesFiltersError: state.Income.TryingViewOrganizationMemberContractInvoicesFiltersError,

        TryingDownloadContractPDF: state.Sales.TryingDownloadContractPDF,
        TryingDownloadContractPDFError: state.Sales.TryingDownloadContractPDFError
    };
};

export default withTranslation()(connect(mapStateToProps, { DownloadContractInvoicePDF, ViewOrganizationMemberContractInvoices, ViewOrganizationMemberContractInvoicesFilters })(OrganizationInvoices));